import { SingleShipmentQuotationResponseDto } from '../../types/single-shipment.quotation.response-dto';
import { SingleShipmentRequestDto } from '../../types/single-shipment.request-dto';

export type SingleShipmentState = {
  boxes: SingleShipmentRequestDto.Box[];
  customer: SingleShipmentRequestDto.Customer;
  declaredValue: number;
  quotation?: {
    deliveryOptionId: string;
  };
  destination: SingleShipmentRequestDto.Address;
  sender: SingleShipmentRequestDto.Address;
  invoiceKey?: string;
  fiscalInformationType: string;
  quotationsList?: SingleShipmentQuotationResponseDto;
  modalCancel?: boolean;
  enableDeliveryOption: boolean;
  modality: string;
};

const initialState: SingleShipmentState = {
  boxes: [],
  customer: {
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    legalIdentification: ''
  },
  destination: {
    address: '',
    city: '',
    country: '',
    name: '',
    neighborhood: '',
    number: '',
    province: '',
    zipCode: '',
    complement: ''
  },
  sender: {
    address: '',
    city: '',
    country: '',
    name: '',
    neighborhood: '',
    number: '',
    province: '',
    zipCode: '',
    complement: ''
  },
  quotation: { deliveryOptionId: '' },
  invoiceKey: '',
  fiscalInformationType: '',
  declaredValue: 0,
  quotationsList: {
    active: [],
    disabled: []
  },
  modalCancel: false,
  enableDeliveryOption: false,
  modality: ''
};

export type Action =
  | {
      type: 'SUBMIT_FORM';
      payload: Partial<SingleShipmentRequestDto>;
    }
  | {
      type: 'LOAD_DELIVERY_OPTIONS';
      payload: SingleShipmentQuotationResponseDto;
    }
  | {
      type: 'SELECT_DELIVERY_OPTION';
      payload: {
        id: string;
        enableModality: boolean;
        modality: string;
      };
    }
  | { type: 'CLEAR_FORM' }
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    };

const singleShipmentReducer = (
  state: SingleShipmentState,
  action: Action
): SingleShipmentState => {
  switch (action.type) {
    case 'SUBMIT_FORM':
      return { ...state, ...action.payload };

    case 'LOAD_DELIVERY_OPTIONS':
      return { ...state, quotationsList: action.payload };

    case 'SELECT_DELIVERY_OPTION':
      return {
        ...state,
        quotation: {
          deliveryOptionId: action.payload.id
        },
        enableDeliveryOption: action.payload.enableModality,
        modality: action.payload.modality
      };

    case 'CLEAR_FORM':
      return initialState;

    case 'OPEN_MODAL':
      return { ...state, modalCancel: true };

    case 'CLOSE_MODAL':
      return { ...state, modalCancel: false };

    default:
      return state;
  }
};

export { initialState, singleShipmentReducer };
