import { useEffect } from 'react';

import { ACTION_LOG_ERROR } from '@tiendanube/nexo';
import { ECountry } from 'App/i18n/i18n.types';
import { useNavigate } from 'react-router-dom';

import { useStoreContext } from 'commons/utils/hooks/useStoreContext';

import nexo from 'nexoClient';

import { ROUTES } from './constants';

const AppRedirect = () => {
  const store = useStoreContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (store.externalStoreId) {
      navigate(ROUTES.DASHBOARD, { replace: true });

      return;
    }

    if (store.country !== ECountry.BRAZIL) {
      navigate(ROUTES.LANDING_PAGE, { replace: true });
    } else {
      nexo.dispatch({ type: ACTION_LOG_ERROR });
    }
  }, [navigate, store.externalStoreId, store.country]);

  return <> </>;
};

export default AppRedirect;
