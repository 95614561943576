import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Icon, Link, Popover, Text } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';

import {
  AMP_ACTIONS,
  AMP_BUTTON,
  sendAmplitudeData
} from 'commons/utils/amplitude';
import { useDashboardContext } from 'commons/utils/hooks/useDashboardContext';
import useOnboarding from 'commons/utils/hooks/useOnboarding';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';

interface OnboardingOrderActionProps {
  visible: boolean;
  callback: () => void;
}
const OnboardingOrderAction: FC<OnboardingOrderActionProps> = ({
  visible,
  callback
}) => {
  const store = useStoreContext();
  const { t } = useTranslation();
  const { state } = useDashboardContext();
  const { toggleOrAddOnboarding } = useOnboarding();

  const handleGoToStep = (shouldAction: boolean) => {
    let amplitudeAction = AMP_ACTIONS.ONBOARDING_ORDER_GO_TO_NEXT;

    const step = {
      step: 3,
      visible: true,
      storeId: store.externalStoreId
    };

    if (shouldAction) {
      amplitudeAction = AMP_ACTIONS.ONBOARDING_ORDER_GO_TO_ACTION;
      callback();
    }

    sendAmplitudeData(AMP_BUTTON, {
      action: amplitudeAction
    });

    toggleOrAddOnboarding(store.externalStoreId, step);
  };

  const onboarding = useMemo(() => {
    return state?.onboarding?.filter(
      (onboarding) => onboarding.storeId === store.externalStoreId
    )[0];
  }, [state?.onboarding, store.externalStoreId]);

  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" width="280" gap="2">
          <Text fontWeight="bold" color="neutral-background">
            {t('widgets.onboarding.step-2.title')}
          </Text>
          <Text color="neutral-background">
            {t('widgets.onboarding.step-2.text')}
          </Text>
          <Box ml="auto">
            <Link
              as="button"
              onClick={() => handleGoToStep(false)}
              appearance="neutral-background"
            >
              {t('widgets.onboarding.step-2.button')}
            </Link>
          </Box>
        </Box>
      }
      visible={
        visible &&
        onboarding?.visible &&
        onboarding.step === 2 &&
        !store.configuration.hasCreatedDispatch
      }
      backgroundColor="primary-interactiveHover"
      position="bottom-end"
      width="280px"
      zIndex="100"
    >
      <Box
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'center' }}
        pl={{ xs: '1-5' }}
        mt="4"
        width="60px"
        onClick={() => handleGoToStep(true)}
      >
        <Icon
          color="neutral-textLow"
          source={<EllipsisIcon />}
          cursor="pointer"
          onClick={() => handleGoToStep(true)}
        />
      </Box>
    </Popover>
  );
};

export default OnboardingOrderAction;
