import { ECountry } from 'App/i18n/i18n.types';

import { BillingCycleStatus } from '../types/billing/billing-cycle-status';

export const IS_PRODUCTION =
  process.env.REACT_APP_CURRENT_ENVIRONMENT === 'production';

export const PERPAGE = 50;
export const PERPAGE_EXTERNAL_SHIPMENTS = 50;
export const PERPAGE_WALLET_REPORT = 100;

export const OTHER_REASON_MIN_LENGTH = 4;
export const BR_MINIMUM_AMOUNT_PRE_PAID = 4;
export const AR_MINIMUM_AMOUNT_PRE_PAID = 50;

export const TOAST_DURATION_IN_MS = 8000;
export const MASSIVE_STATUS = 'massiveStatus';
export const ACTION_REPRINT_DOCUMENTS = 'reprint-documents';
export const SAIBA_MAIS_LINK =
  'https://atendimento.nuvemshop.com.br/pt_BR/nuvem-envio/como-pagar-pelos-meus-envios-atraves-do-nuvem-envio';
export const BR_LANDING_PAGE =
  'https://atendimento.nuvemshop.com.br/pt_BR/ponto-de-postagem/o-que-e-o-ponto-de-postagem-do-nuvem-envio';

export const AR_LANDING_PAGE =
  'https://ayuda.tiendanube.com/es_ES/envio-nube/que-es-envio-nube-y-como-activarlo-en-mi-tienda';

export const MX_LANDING_PAGE =
  'https://ayuda.tiendanube.com/es_ES/envio-nube/que-es-envio-nube-y-como-activarlo-en-mi-tienda';

export const VIDEO_ALLOW_POPUP = 'https://youtu.be/YxQ0V2EDtVc?feature=shared';
export const DISPLAY_FRIENDLY_DATE = 'dd-MM-yyyy';
export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const APPCUESID = 126721;
export const HELP_OWN_CONTRACT_LINK =
  'https://static.helpjuice.com/helpjuice_production/uploads/upload/image/7634/3791781/Criação_da_senhas_novas_apis_Correios_240124_172652.pdf';

export const ZIPCODE_MASK = '99999-999';
export const CPF_MASK = '999.999.999-999';
export const CNPJ_MASK = '99.999.999/9999-99';
export const NFE_MASK =
  '9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999';
export const PHONE_MASK = '(99) 99999-9999';
export const HEIGHT_MASK = '999';
export const DEPTH_MASK = '999';
export const WIDTH_MASK = '999';
export const WEIGHT_MASK = '99999';
export const CURRENCY_MASK = '9999999999';

export const KEYCODE_ENTER = 'enter';

export const PARTNER_PEGAKI_MAPS =
  'https://locationsmap.pegaki.com.br/pontos/11934';

export const PARTNER_JADLOG_MAPS =
  'https://www.jadlog.com.br/siteInstitucional/pickup.jad';
export const PARTNER_LOGGI_MAPS =
  'https://www.loggi.com/loggi-envio-itens-brasil/';
export const PARTNER_CORREIOS_MAPS =
  'https://mais.correios.com.br/app/index.php';

export const PARTNERS_MAPS = {
  jadlog: PARTNER_JADLOG_MAPS,
  loggi: PARTNER_LOGGI_MAPS
};

export const PARTNER_JADLOG_TRACKING = 'https://jadlog.com.br/tracking?cte=';
export const PARTNER_JADLOG_LANDING_PAGE =
  'https://atendimento.nuvemshop.com.br/pt_BR/-nuvem-envio-jadlog/como-funciona-o-nuvem-envio-jadlog-com-pontos-de-postagem';
export const GOOGLE_MAPS_LINK = 'https://maps.google.com/?q=';

export enum SIDEMODALS {
  ADDCREDIT = 'addCredit',
  BILLING_ADJUSTS = 'billingAdjusts',
  CANCELLATION_ORDERS = 'cancellationOrders',
  CHARGE_HISTORY = 'chargeHistory',
  FILTERS = 'filters',
  FREIGHT_CALCULATOR = 'freightCalculator',
  FREIGHT_CALCULATOR_TABLE = 'freightCalculatorTable',
  GENERATE_FILES = 'generateFiles',
  GENERATE_ROMANEIO = 'generateRomaneio',
  TUTORIALS_NUVEM_ENVIO = 'tutorialsNuvemEnvio',
  OWN_CONTRACT_CORREIOS = 'ownContractCorreios',
  DETAILS_SHIPMENT_SIMULATE = 'simulatePrePaid',
  DETAILS_SHIPMENT_PRINT = 'printFiles',
  DETAILS_SHIPMENT_QUOTATION = 'quotation',
  DETAILS_SHIPMENT_CANCELLATION_ORDER = 'cancellationOrder',
  EXTERNAL_SHIPMENTS_QUOTATION = 'externalShipmentsQuotation',
  WELCOME_GO_PLUS = 'welcomeGoPlus',
  WELCOME_FIRST_SHIPMENT = 'welcomeFirstShipment',
  GENERATE_FILES_FROM_ORDERS = 'generateFilesFromOrders'
}

export enum BANNERS {
  GOPLUS = 'show-go-plus-banner',
  JADLOG = 'show-jadlog-activation-banner',
  LOGGI = 'show-loggi-activation-banner'
}

export enum CorreiosContract {
  NE = 'NE',
  BALCAO = 'Balcao',
  OWN_CONTRACT = 'Proprio'
}

export const BREAKPOINTS = {
  xs: 0,
  md: 672,
  lg: 1024
};

export const APP_IDS_PER_COUNTRY = {
  [ECountry.ARGENTINA]: {
    production: 9075,
    staging: 7977
  },
  [ECountry.MEXICO]: {
    production: 8539,
    staging: 7660
  },
  [ECountry.BRAZIL]: {
    production: 4190,
    staging: 3810
  }
};

export const ALLOWED_ARGENTINA_STORES_CREATE_SUCURSAL_DISABLED_OPTION_PRODUCTION =
  ['4027400', '689548'];

export const NEW_SINGLE_SHIPMENT_BRAZIL_PRODUCTION = [
  '4760699',
  '5089526',
  '5558304',
  '2010264',
  '4105937',
  '1723101',
  '5038518',
  '4096456',
  '4086789',
  '4161085',
  '3367653',
  '3243518',
  '1793250',
  '1983293',
  '1938062',
  '2204246',
  '2140044',
  '2730149',
  '1174246',
  '1160970',
  '2013884',
  '5261761',
  '1859222',
  '2153207',
  '1216674',
  '905119',
  '1613018',
  '1586147',
  '911964',
  '2492225',
  '4896286',
  '1729841',
  '1260598',
  '490018',
  '1322065',
  '3834423',
  '4851893',
  '2849813',
  '4172545',
  '4462121',
  '2545892',
  '1730583',
  '1902764',
  '4124010',
  '2328575',
  '1697171',
  '1092762',
  '4811502',
  '1985196',
  '5234180',
  '1161619',
  '3575658',
  '3625348',
  '4017118',
  '2287044',
  '459525',
  '2475564',
  '1072066',
  '1824411',
  '2016622',
  '3369811',
  '1185676',
  '932273',
  '1470913',
  '2513412',
  '2837106',
  '1280329',
  '3370922',
  '1938916',
  '3477031',
  '1165432',
  '2978643',
  '3469876',
  '807921',
  '3785129',
  '1100139',
  '2775989',
  '4773518',
  '1219230',
  '866836',
  '118472',
  '1729733',
  '5148413',
  '652415',
  '5043272',
  '2769999',
  '1278032',
  '4214733',
  '316607',
  '1474791',
  '1218523',
  '3061241',
  '4820500',
  '1964619',
  '3751477',
  '1927335',
  '1835039',
  '2116229',
  '1185507',
  '4855952',
  '1893480',
  '3873389',
  '709195',
  '1022453',
  '337959',
  '4866899',
  '1178049',
  '5052402',
  '3725315',
  '2027582',
  '2568670',
  '2819135',
  '1621928',
  '2982608',
  '1108046',
  '3557785',
  '880538',
  '2339116',
  '1609166',
  '4487187',
  '3776596',
  '1348713',
  '1094428',
  '26728',
  '5459286',
  '4949169',
  '881402',
  '2189494',
  '970799',
  '3277021',
  '2538993',
  '4808682',
  '4697737',
  '4258621',
  '1918782',
  '2973920',
  '3276630',
  '4376044',
  '1441111',
  '1045628',
  '4206262',
  '1904302',
  '2074519',
  '4548476',
  '3191984',
  '4182767',
  '3826593',
  '1088727',
  '680661',
  '2600262',
  '4523877',
  '2623561',
  '1756099',
  '4420316',
  '3340911',
  '1772754',
  '2257880',
  '1815528',
  '1205055',
  '2284515',
  '2255684',
  '813573',
  '1311488',
  '203714',
  '47054',
  '1910684',
  '1988671',
  '2251280',
  '3223467',
  '1945313',
  '966252',
  '2247867',
  '1384696',
  '5370731',
  '528751',
  '3134751',
  '3725007',
  '4573983',
  '1996264',
  '95829',
  '2456473',
  '3412096',
  '1387644',
  '2218323',
  '2955628',
  '4232145',
  '4472820',
  '1068213',
  '2090508',
  '2585140',
  '2937958',
  '1229953',
  '2508222',
  '1116461',
  '52490',
  '861649',
  '345882',
  '2357472',
  '687992',
  '3190228',
  '1743445',
  '4192438',
  '4310239',
  '927274',
  '1551368',
  '1772247',
  '2827004',
  '2845814',
  '955181',
  '2275085',
  '595405',
  '1575734',
  '2196320',
  '3603106',
  '3385278',
  '628011',
  '2943843',
  '1862434',
  '1140459',
  '165324',
  '1004291',
  '1409698',
  '3990509',
  '976557',
  '1652023',
  '1145895',
  '1461448',
  '541802',
  '1202019',
  '3294938',
  '4163662',
  '5205048',
  '4414234',
  '1854440',
  '2946966',
  '1389266',
  '1109989',
  '4807835',
  '4812498',
  '4247219',
  '2093480',
  '1053068',
  '811379',
  '1374630',
  '3152380',
  '1172207',
  '2490937',
  '2487831',
  '4626135',
  '4069445',
  '2362961',
  '4120828',
  '475284',
  '1172399',
  '2507024',
  '4769375',
  '1633610',
  '1170890',
  '1141406',
  '3888337',
  '2799545',
  '2403117',
  '985426',
  '1764358',
  '4302549',
  '1608772',
  '3442262',
  '1615141',
  '3315415',
  '993651',
  '4263853',
  '3173871',
  '4356032',
  '2832813',
  '1384296',
  '3998113',
  '2197685',
  '1228964',
  '3007758',
  '1711873',
  '3780779',
  '4522780',
  '557929',
  '2254921',
  '3611750',
  '2485887',
  '4488432',
  '1954215',
  '4660843',
  '2950233',
  '4544018',
  '2256887',
  '4309128',
  '3813107',
  '838349',
  '3852570',
  '346669',
  '520947',
  '3377070',
  '2646966',
  '5091139',
  '1005515',
  '3679339',
  '4104840',
  '1702484',
  '1449850',
  '1434427',
  '1295147',
  '1375371',
  '2112022',
  '1037652',
  '4384768',
  '1603483',
  '1868283',
  '5287753',
  '2087727',
  '1110224',
  '2899529',
  '2738471',
  '2741478',
  '4832161',
  '1973393',
  '3538071',
  '2909674',
  '2768605',
  '1855625',
  '1240257',
  '4498707',
  '4960597',
  '3071165',
  '2171837',
  '1700257',
  '5302955',
  '2521675',
  '4037325',
  '2314558',
  '564004',
  '268921',
  '3805200',
  '299236',
  '2400614',
  '3638104',
  '2485646',
  '3486909',
  '3852742',
  '2380306',
  '1901086',
  '2083429',
  '3442124',
  '1613910',
  '3557505',
  '4991658',
  '2160996',
  '176185',
  '691809',
  '1917820',
  '3414091',
  '3742153',
  '3255415',
  '2955098',
  '1917705',
  '4519006',
  '1101506',
  '1152439',
  '1898193',
  '4921775',
  '2046673',
  '4730503',
  '2792373',
  '4451449',
  '5447203',
  '1597028',
  '879746',
  '3234979',
  '2534506',
  '1584261',
  '2144728',
  '1119176',
  '3924558',
  '2999917',
  '2307858',
  '1633029',
  '1240703',
  '2082496',
  '1410449',
  '3981644',
  '3519352',
  '1722427',
  '2693849',
  '5506786',
  '4213876',
  '4903243',
  '1206817',
  '2094671',
  '1923841',
  '4418657',
  '1048192',
  '1829963',
  '52201',
  '2344756',
  '1594044',
  '5454541',
  '3717274',
  '5089483',
  '1200397',
  '3096202',
  '2462326',
  '965865',
  '4667820',
  '3039548',
  '2384682',
  '1674495',
  '4681664',
  '4511655',
  '1111546',
  '1289672',
  '2504740',
  '2024998',
  '1006620',
  '4424216',
  '3965221',
  '2085176',
  '3011890',
  '2023947',
  '3911999',
  '4259843',
  '3734736',
  '2095639',
  '1361723',
  '2006775',
  '5257170',
  '2296090',
  '3477154',
  '4448460',
  '5444453',
  '5438156',
  '4485925',
  '4135138',
  '4920954',
  '3520279',
  '2519807',
  '3152347',
  '5033744',
  '3785423',
  '2077878',
  '4675270',
  '4303195',
  '2856005',
  '3688484',
  '2376049',
  '1125019',
  '2616096',
  '155014',
  '3546433',
  '4254452',
  '3035703',
  '3935406',
  '3731955',
  '4202975',
  '1330164',
  '4241754',
  '1212185',
  '1895514',
  '1749937',
  '2056354',
  '3870997',
  '2189482',
  '780898',
  '1717384',
  '3515080',
  '3924415',
  '2196615',
  '4329517',
  '3144866',
  '2492382',
  '1295918',
  '2497143',
  '3977789',
  '2027335',
  '3866024',
  '4135890',
  '913310',
  '842051',
  '2044132',
  '1861700',
  '1371108',
  '1161240',
  '2980633',
  '1380436',
  '4983234',
  '1101028',
  '4089225',
  '1821202',
  '1888237',
  '4280375',
  '3390515',
  '3819163',
  '2019318',
  '3766894',
  '1170924',
  '4963909',
  '5094479',
  '4498728',
  '84506',
  '2607480',
  '3968992',
  '1098242',
  '4032952',
  '4951445',
  '1206964',
  '3430465',
  '4061627',
  '891992',
  '236797',
  '3520849',
  '2419307',
  '2201480',
  '873495',
  '4611174',
  '729305',
  '5434626',
  '1669092',
  '2601155',
  '2507651',
  '2590215',
  '1987801',
  '645344',
  '5499940',
  '2491494',
  '1659664',
  '4079841',
  '4079979',
  '5053852',
  '1216761',
  '301872',
  '4339524',
  '4503734',
  '4810704',
  '2237436',
  '2399054',
  '2503589',
  '2249928',
  '2434823',
  '1067305',
  '5497222',
  '1953594',
  '2144498',
  '5586125',
  '1815175',
  '5169792',
  '1025624',
  '525078',
  '1080001',
  '1451796',
  '166028',
  '2007024',
  '115023',
  '1594205',
  '2837615',
  '962371',
  '3803779',
  '3898878',
  '3182606',
  '3220547',
  '1235936',
  '5268373',
  '2007469',
  '1635724',
  '1278012',
  '5413588',
  '4382587',
  '4396718',
  '1723986',
  '1464804',
  '1992974',
  '1539074',
  '2439927',
  '1251469',
  '5532819',
  '1858940',
  '3900713',
  '3429212',
  '1529193',
  '3991200',
  '1178413',
  '4452248',
  '5024531',
  '951831',
  '2578097',
  '1294479',
  '4876204',
  '5558492',
  '3407441',
  '2189457',
  '4541432',
  '5146352',
  '4602407',
  '2260328',
  '1539866',
  '3954609',
  '1732820',
  '1348405',
  '2856816',
  '544192',
  '3762304',
  '3367538',
  '4258665',
  '1171016',
  '1697080',
  '1275065',
  '1060011',
  '2862974',
  '1485158',
  '5378313',
  '4088226',
  '4212219',
  '3070481',
  '239023',
  '3487675',
  '3396052',
  '1790270',
  '2011135',
  '2050005',
  '720836',
  '2057003',
  '3458530',
  '5063384',
  '1792096',
  '1788863',
  '2257924',
  '4558766',
  '1185028',
  '2223714',
  '5376615',
  '3049512',
  '3771136',
  '1346218',
  '3136273',
  '2888122',
  '1145831',
  '4087039',
  '3610464',
  '4062169',
  '4634595',
  '1897634',
  '867924',
  '795669',
  '1752107',
  '5139497',
  '2061809',
  '3188101',
  '2042477',
  '1634188',
  '1602232',
  '2585218',
  '5108614',
  '385492',
  '4457117',
  '2146461',
  '3980677',
  '3330283',
  '2855428',
  '1472179',
  '3979432',
  '4564285',
  '3210364',
  '2369870',
  '2164039',
  '4309931',
  '3025033',
  '1929827',
  '4467323',
  '4599688',
  '4877662',
  '4864307',
  '2279835',
  '4621971',
  '3421822',
  '2032465',
  '4381217',
  '932740',
  '3656596',
  '3528943',
  '5004985',
  '5647416',
  '1415999',
  '4577813',
  '4495776',
  '2076103',
  '2074310',
  '3212851',
  '4393444',
  '2077441',
  '4314318',
  '1131443',
  '1882454',
  '3824408',
  '2253959',
  '4980317',
  '3399506',
  '1816590',
  '710352',
  '2669474',
  '3755716',
  '2561326',
  '2467781',
  '3645443',
  '471119',
  '3709031',
  '5490160',
  '984048',
  '2626961',
  '3553333',
  '5524543',
  '4308877',
  '3436551',
  '849742',
  '2033657',
  '5151701',
  '4048311',
  '4241490',
  '3938259',
  '2911674',
  '1277511',
  '4699773',
  '3285942',
  '4307016',
  '1704772',
  '4999485',
  '1133884',
  '804933',
  '3678360',
  '5139465',
  '5138913',
  '2125239',
  '4232697',
  '849092',
  '1320299',
  '2023842',
  '2007373',
  '1565500',
  '2588384',
  '5383981',
  '939358',
  '4643107',
  '4694796',
  '2254605',
  '4132809',
  '3355331',
  '3925281',
  '5463135',
  '2727114',
  '1641163',
  '4260710',
  '2156357',
  '3156154',
  '2169650',
  '4672543',
  '1055974',
  '4861936',
  '3582434',
  '1480716',
  '2295370',
  '2809739',
  '1900131',
  '2819186',
  '1861159',
  '3381771',
  '1344384',
  '2096788',
  '1709280',
  '4276993',
  '1410705',
  '1143648',
  '2131778',
  '193243',
  '2861598',
  '3612496',
  '2639895',
  '2942785',
  '2607195',
  '3171975',
  '4002390',
  '4574997',
  '4420776',
  '4507578',
  '3900610',
  '1332465',
  '1661748',
  '4330657',
  '1151629',
  '5596946',
  '2455384',
  '326884',
  '2240832',
  '3993269',
  '4166555',
  '1015422',
  '1033022',
  '1739475',
  '1890000',
  '907770',
  '1601119',
  '1352322',
  '5147588',
  '1633678',
  '5022693',
  '5571809',
  '1656619',
  '5340361',
  '2054802',
  '2008996',
  '3489027',
  '1395920',
  '5212412',
  '5384189',
  '2836035',
  '5124491',
  '4960984',
  '1902111',
  '4528416',
  '4568795',
  '4924468',
  '2406814',
  '994238',
  '2171991',
  '5078827',
  '2191334',
  '961473',
  '1326735',
  '1117646',
  '1208610',
  '3529012',
  '4542600',
  '2843958',
  '4922009',
  '3937128',
  '103217',
  '966591',
  '5076925',
  '1341486',
  '2164928',
  '1285765',
  '2280235',
  '1756622',
  '2107875',
  '630942',
  '3538553',
  '1343121',
  '2137661',
  '1985118',
  '1714474',
  '1183974',
  '2065322',
  '2436382',
  '4153421',
  '4786125',
  '5543784',
  '3990801',
  '4538572',
  '692686',
  '4441454',
  '1674385',
  '3848089',
  '1201990',
  '5438922',
  '3833120',
  '1806776',
  '674921',
  '3062746',
  '3838680',
  '1286838',
  '1633082',
  '3334803',
  '3459517',
  '2489379',
  '5296974',
  '1316817',
  '500225',
  '5603854',
  '1342049',
  '1958237',
  '5337065',
  '1229866',
  '3982250',
  '1629439',
  '3427897',
  '5323753',
  '291045',
  '2559852',
  '5496315',
  '2525466',
  '1627654',
  '4510739',
  '2629830',
  '4635413',
  '3795023',
  '2363908',
  '196601',
  '4603918',
  '3264373',
  '2418939',
  '1151480',
  '5533740',
  '3254315',
  '2380173',
  '192412',
  '2986990',
  '1734717',
  '1625510',
  '5502673',
  '4653744',
  '3814608',
  '1602027',
  '1303853',
  '1344006',
  '4480066',
  '4999298',
  '1707726',
  '1158683',
  '366561',
  '5492545',
  '139793',
  '2976912',
  '415351',
  '1998586',
  '4023948',
  '3812023',
  '5238131',
  '3809617',
  '3560502',
  '4660313',
  '4832314',
  '5505579',
  '2323384',
  '3652990',
  '2257640',
  '2924007',
  '2560687',
  '1787247',
  '3187825',
  '4349576',
  '4470960',
  '3368748',
  '1371933',
  '2964029',
  '1153312',
  '3824963',
  '3625814',
  '2977912',
  '1903656',
  '1322153',
  '4351340',
  '1966181',
  '5518669',
  '2043800',
  '1787530',
  '4472946',
  '3831745',
  '1864084',
  '2067652',
  '2078417',
  '875499',
  '2245688',
  '301597',
  '4983920',
  '1059540',
  '4337926',
  '1245767',
  '4743220',
  '5374158',
  '4692206',
  '5197354',
  '3744683',
  '3962873',
  '3559398',
  '1408408',
  '3070837',
  '4872117',
  '4367598',
  '3105912',
  '1854941',
  '5332310',
  '2714118',
  '3024004',
  '4881877',
  '2408421',
  '2067811',
  '4937131',
  '3154669',
  '2301245',
  '1704325',
  '2316293',
  '2368707',
  '2273996',
  '3712466',
  '3448991',
  '4551624',
  '417579',
  '1381115',
  '4198160',
  '2245624',
  '3090724',
  '4010377',
  '3754925',
  '4785421',
  '3820042',
  '1172759',
  '3052950',
  '4364500',
  '4661051',
  '4026988',
  '4710092',
  '2228775',
  '3094984',
  '2547952',
  '3758118',
  '5591222',
  '1979237',
  '3277539',
  '5256202',
  '3093823',
  '5607909',
  '1471693',
  '2115076',
  '2921359',
  '5381613',
  '3925640',
  '3192628',
  '4363871',
  '4944432',
  '4449042',
  '3720151',
  '2885409',
  '3530665',
  '2386619',
  '2225780',
  '2600327',
  '4319048',
  '1742875',
  '568279',
  '1155147',
  '959137',
  '2450804',
  '4187481',
  '4726377',
  '5319156',
  '2474783',
  '4039243',
  '1937508',
  '2941443',
  '4913390',
  '4323722',
  '3943030',
  '3028567',
  '3797275',
  '2548850',
  '1902656',
  '1055145',
  '4754948',
  '848500',
  '4340395',
  '2497108',
  '2028581',
  '2481458',
  '1788872',
  '5092302',
  '5561871',
  '1915310',
  '3122108',
  '5568633',
  '2429917',
  '2047113',
  '1863388',
  '5346894',
  '2456289',
  '835074',
  '4788011',
  '670533',
  '1455102',
  '1033594',
  '5266235',
  '4002467',
  '1819316',
  '4140414',
  '2580632',
  '4046394',
  '5248445',
  '1147323',
  '1501073',
  '3860330',
  '1118513',
  '4296353',
  '815807',
  '4064148',
  '330515',
  '2914349',
  '2855936',
  '4770826',
  '1128754',
  '1049846',
  '5564766',
  '621080',
  '4284165',
  '2130632',
  '4941565',
  '2456790',
  '888021',
  '3957548',
  '5518180',
  '3841701',
  '3156543',
  '3309656',
  '1884309',
  '3690554',
  '2462095',
  '4491604',
  '1303652',
  '4919747',
  '2804908',
  '1670075',
  '5076266',
  '4265833',
  '3742571',
  '3019438',
  '4908600',
  '4233032',
  '2808097',
  '592733',
  '5395621',
  '3357285',
  '4752899',
  '4690251',
  '982080',
  '5109378',
  '1334929',
  '3306033',
  '3358524',
  '1935222',
  '2761992',
  '3818303',
  '3625838',
  '4511213',
  '4667946',
  '2889631',
  '4603897',
  '5184889',
  '2325305',
  '2289529',
  '3856003',
  '5155413',
  '2141001',
  '4580211',
  '3559736',
  '1673795',
  '4361354',
  '4842001',
  '5656517',
  '2791098',
  '4285942',
  '4649977',
  '2617744',
  '2937677',
  '1527935',
  '5164448',
  '1459937',
  '4386471',
  '5146129',
  '2415902',
  '4708337',
  '4032092',
  '1222426',
  '4081282',
  '4873412',
  '4198307',
  '5229124',
  '2093852',
  '2020791',
  '4150823',
  '3951973',
  '5018270',
  '3356889',
  '4557070',
  '5549729',
  '5616046',
  '5059964',
  '2571492',
  '1388890',
  '4907314',
  '5487128',
  '2564467',
  '1720930',
  '5351016',
  '5525555',
  '4400279',
  '3220770',
  '1790157',
  '2925140',
  '2419306',
  '1770178',
  '5308388',
  '3220715',
  '3307093',
  '2678500',
  '1705114',
  '4465026',
  '2029481',
  '5105222',
  '3014194',
  '5295893',
  '5267922',
  '1010991',
  '2109887',
  '3980196',
  '1486362',
  '4382813',
  '1416764',
  '2451916',
  '895737',
  '2449012',
  '5493095',
  '3304466',
  '4866547',
  '5237774',
  '4568577',
  '842106',
  '1555230',
  '2077752',
  '5549398',
  '1854447',
  '3908119',
  '5315058',
  '999015',
  '5002221',
  '3723506',
  '4141146',
  '3920382',
  '1705177',
  '5365534',
  '4349928',
  '2045370',
  '1084708',
  '4720662',
  '4990108',
  '4449166',
  '3739681',
  '3287374',
  '3160795',
  '4896947',
  '5107460',
  '2326480',
  '2222150',
  '3891491',
  '3718788',
  '2274168',
  '5103243',
  '1602351',
  '3965456',
  '3558662',
  '2470762',
  '675244',
  '4330601',
  '4376740',
  '4364222',
  '5273665',
  '2331231',
  '3730578',
  '2901967',
  '5018963',
  '5593020',
  '1163666',
  '1372598',
  '3100487',
  '5184308',
  '1717295',
  '2377178',
  '4147750',
  '5680799',
  '4462900',
  '1177399',
  '2047668',
  '1121678',
  '2788288',
  '4911841',
  '1325777',
  '5429249',
  '1475900',
  '5103777',
  '5260370',
  '4790118',
  '2756649',
  '4500494',
  '3158798',
  '4515997',
  '1553982',
  '2374552',
  '1123216',
  '1689955',
  '2286389',
  '2114379',
  '1390194',
  '3895325',
  '119167',
  '1603424',
  '3244312',
  '1244316',
  '2482296',
  '5322387',
  '5185019',
  '1422663',
  '3277431',
  '4936958',
  '3012435',
  '3012452',
  '4030470',
  '1300302',
  '3880106',
  '2554314',
  '4270448',
  '5505200',
  '4006306',
  '4520916',
  '1540158',
  '3562743',
  '3990581',
  '933675',
  '1472651',
  '3121132',
  '5081445',
  '817334',
  '1847154',
  '1089526',
  '1330242',
  '1297726',
  '5244670',
  '1489512',
  '3646040',
  '3567565',
  '3707567',
  '2640885',
  '2538837',
  '2011332',
  '1739756',
  '4906663',
  '3079891',
  '5185807',
  '137913',
  '3732839',
  '2553268',
  '5167320',
  '4732681',
  '5083288',
  '4541291',
  '1950175',
  '1814817',
  '1310170',
  '2232023',
  '5273108',
  '2339610',
  '4220769',
  '3449357',
  '2613755',
  '2308805',
  '3044352',
  '3572722',
  '4909040',
  '5494593',
  '3336524',
  '1276808',
  '787206',
  '3428608',
  '3322942',
  '4972471',
  '5301803',
  '3688466',
  '4268641',
  '1628957',
  '2508194',
  '3156479',
  '4660367',
  '2139683',
  '3670469',
  '4738507',
  '4786562',
  '5520017',
  '3312267',
  '5553600',
  '4026557',
  '3135463',
  '5201979',
  '2151385',
  '2366499',
  '1332941',
  '1572213',
  '2342648',
  '3065266',
  '2004960',
  '4605800',
  '5112787',
  '5524854',
  '4457028',
  '5259020',
  '5282379',
  '3731141',
  '5360922',
  '4578651',
  '3718255',
  '1198689',
  '1452925',
  '4909364',
  '3107087',
  '366840',
  '1008662',
  '1469261',
  '802500',
  '5373574',
  '4995869',
  '4833479',
  '4141577',
  '998611',
  '1366217',
  '4510713',
  '1754992',
  '3061881',
  '4019879',
  '5425841',
  '1159490',
  '4704181',
  '4206511',
  '5010653',
  '2537845',
  '1721400',
  '2424530',
  '1325320',
  '4176940',
  '2556441',
  '4861485',
  '5091811',
  '4946040',
  '4178209',
  '3527836',
  '2055990',
  '2474944',
  '2293001',
  '1997496',
  '4293086',
  '4905536',
  '3250142',
  '3094879',
  '571418',
  '4711640',
  '4623583',
  '2929084',
  '1539337',
  '5048295',
  '2490799',
  '3986237',
  '4670288',
  '2112300',
  '5105555',
  '5445185',
  '5259256',
  '5399041',
  '3506108',
  '769069',
  '2718054',
  '1245812',
  '4199919',
  '1211364',
  '2075491',
  '1214732',
  '3217260',
  '3554600',
  '2301222',
  '1380101',
  '5502667',
  '1199741',
  '5204708',
  '3971712',
  '5234651',
  '2826517',
  '854677',
  '3409449',
  '1921900',
  '1552437',
  '3940008',
  '4466301',
  '5185661',
  '3086666',
  '1205462',
  '1125717',
  '5502262',
  '1800891',
  '1852313',
  '4974609',
  '1472134',
  '4511496',
  '2797683',
  '4258632',
  '4483250',
  '1945535',
  '2766616',
  '4989613',
  '5434518',
  '3994466',
  '1305885',
  '4979107',
  '1965936',
  '179029',
  '5319099',
  '880618',
  '5528995',
  '1444525',
  '199360',
  '5615162',
  '3979362',
  '3192846',
  '5125664',
  '5487574',
  '1418755',
  '4303540',
  '4941352',
  '1952823',
  '1382552',
  '2947945',
  '4054429',
  '2225181',
  '5447201',
  '1308508',
  '5351078',
  '2741467',
  '155794',
  '1756159',
  '3603052',
  '3589151',
  '4233805',
  '1352223',
  '2749740',
  '3712647',
  '5573628',
  '4247382',
  '4500717',
  '5287791',
  '2971987',
  '5443670',
  '3904233',
  '2203351',
  '5563574',
  '2141903',
  '1211612',
  '5332766',
  '1916383',
  '1825551',
  '5524415',
  '3355334',
  '5522124',
  '1775123',
  '4598260',
  '5403873',
  '2707204',
  '5574978',
  '3372345',
  '2601471',
  '3350616',
  '997573',
  '4381975',
  '1516444',
  '3835831',
  '3628289',
  '2379410',
  '2456366',
  '5065211',
  '5674708',
  '5490037',
  '3617102',
  '1094901',
  '4048301',
  '4787364',
  '5119859',
  '1999633',
  '1749589',
  '5362275',
  '4240047',
  '1533895',
  '4248068',
  '3802244',
  '4056957',
  '1178148',
  '3566210',
  '2339280',
  '2023177',
  '4234850',
  '5683130',
  '3807880',
  '4623360',
  '3651963',
  '5672628',
  '4650843',
  '2493399',
  '4569530',
  '2779212',
  '3095665',
  '2192030',
  '2224314',
  '1968674',
  '4429254',
  '4372452',
  '3471541',
  '3408176',
  '5159935',
  '3118754',
  '4853833',
  '4100011',
  '2960696',
  '3472404',
  '2512518',
  '5417664',
  '5103702',
  '5151857',
  '3790655',
  '3903846',
  '3330095',
  '3064590',
  '2275867',
  '2052860',
  '1436505',
  '4714804',
  '4584472',
  '3463340',
  '2090323',
  '4578397',
  '87501',
  '1100568',
  '2738841',
  '1833842',
  '4425385',
  '4486908',
  '4354543',
  '5523259',
  '2320649',
  '4676233',
  '1974691',
  '1856499',
  '3965066',
  '844057',
  '4476523',
  '5445764',
  '1380470',
  '3223993',
  '4666166',
  '5194278',
  '5381523',
  '3705996',
  '5539108',
  '5499719',
  '2183131',
  '1839020',
  '4526106',
  '5444681',
  '3341158',
  '5508774',
  '2808066',
  '5427623',
  '1158878',
  '2759159',
  '4507315',
  '4215905',
  '3941131',
  '2076627',
  '1543911',
  '4703335',
  '910783',
  '5088125',
  '5280736',
  '3260728',
  '2907210',
  '2071788',
  '851373',
  '4141533',
  '3914535',
  '5542197',
  '5513061',
  '3849236',
  '3582270',
  '1994582',
  '1768590',
  '5535876',
  '5438376',
  '4658252',
  '1155388',
  '3028493',
  '5489771',
  '4939043',
  '5190946',
  '1367470',
  '3843101',
  '949775',
  '4105094',
  '4088467',
  '4420184',
  '3860876',
  '5665921',
  '3690204',
  '5044706',
  '5115404',
  '5298514',
  '4147146',
  '2073990',
  '3643021',
  '4950252',
  '2631651',
  '1529068',
  '5492961',
  '4518079',
  '5358395',
  '3924751',
  '5038355',
  '1834395',
  '5557825',
  '1095106',
  '5447599',
  '3671446',
  '2904650',
  '3614007',
  '3808170',
  '1393033',
  '3803183',
  '3564055',
  '5398150',
  '5417436',
  '2687946',
  '2307458',
  '2701649',
  '3104991',
  '5320062',
  '253866',
  '4897102',
  '3838380',
  '5409076',
  '4736961',
  '5140592',
  '2895740',
  '4011979',
  '4964346',
  '1669071',
  '3772564',
  '2621061',
  '1116197',
  '4649795',
  '1126711',
  '4512710',
  '2212187',
  '1084137',
  '5151966',
  '2074750',
  '5678296',
  '4326425',
  '4990580',
  '5478008',
  '4887283',
  '1965255',
  '2262570',
  '1901981',
  '4317718',
  '5195011',
  '4400189',
  '2294920',
  '5156364',
  '5480967',
  '3366115',
  '1841824',
  '3385744',
  '1250168',
  '4878937',
  '2390369',
  '833380',
  '3293287',
  '5213488',
  '1661784',
  '2669486',
  '571194',
  '4992321',
  '1849328',
  '1108812',
  '1507852',
  '1756369',
  '1813637',
  '2221590',
  '4231793',
  '4332977',
  '1008110',
  '2349577',
  '4324536',
  '1319930',
  '3398068',
  '4753933',
  '5139124',
  '4152018',
  '3736641',
  '3279194',
  '5535927',
  '1892903',
  '3380194',
  '4999450',
  '2739530',
  '4246874',
  '5532739',
  '3726002',
  '1318482',
  '4005708',
  '2191893',
  '5003098',
  '2453942',
  '3509509',
  '5480860',
  '5726997',
  '3672393',
  '4080716',
  '4723302',
  '1190224',
  '5139073',
  '5441694',
  '4670497',
  '4449446',
  '2476228',
  '1155210',
  '3679463',
  '3981193',
  '2195040',
  '1766003',
  '5041944',
  '1161091',
  '5562413',
  '3173569',
  '5370035',
  '5448178',
  '4549704',
  '2510083',
  '4564577',
  '5515124',
  '2145169',
  '2892714',
  '1886076',
  '2080028',
  '1135255',
  '1852745',
  '5700750',
  '4940902',
  '385079',
  '4513800',
  '3194107',
  '5353758',
  '4908027',
  '3634451',
  '5603075',
  '5003135',
  '2162544',
  '4982258',
  '3421873',
  '1542052',
  '3075750',
  '3563461',
  '122445',
  '1528801',
  '3986602',
  '3683149',
  '5542866',
  '5503032',
  '1029190',
  '2691516',
  '2302533',
  '4129167',
  '4157457',
  '5377968',
  '3971155',
  '2981790',
  '2082352',
  '3735242',
  '4732098',
  '4582971',
  '4356615',
  '4511571',
  '5266796',
  '4530606',
  '5015139',
  '1133855',
  '3350518',
  '5167514',
  '1101746',
  '888350',
  '1329916',
  '1649349',
  '67827',
  '4807944',
  '5426424',
  '5455461',
  '2832402',
  '2084421',
  '2478841',
  '3766118',
  '2641465',
  '3211365',
  '5539453',
  '2258175',
  '1979741',
  '5507056',
  '404736',
  '2294811',
  '3742979',
  '5566685',
  '2301062',
  '5253340',
  '4937635',
  '3815409',
  '4264882',
  '5530686',
  '2208684',
  '4010525',
  '2586378',
  '1129087',
  '4673158',
  '1354409',
  '4324698',
  '1580360',
  '3840883',
  '5632157',
  '3900327',
  '3481239',
  '1478493',
  '2611638',
  '1114313',
  '2083111',
  '5508423',
  '2402718',
  '4513077',
  '4671527',
  '3327709',
  '3303397',
  '5249943',
  '5285930',
  '3865431',
  '98564',
  '2657868',
  '1788330',
  '2894511',
  '3565850',
  '5190774',
  '1486907',
  '2692169',
  '4814373',
  '5441544',
  '1759334',
  '3147799',
  '4304676',
  '1362546',
  '4284017',
  '2601066',
  '1859477',
  '1658182',
  '5563990',
  '4726639',
  '902471',
  '1701487',
  '4819890',
  '4290478',
  '5169598',
  '4934812',
  '3904124',
  '1238775',
  '5544949',
  '3563993',
  '1163966',
  '2748908',
  '1965442',
  '5377895',
  '3842788',
  '5597162',
  '5515909',
  '4557283',
  '2320379',
  '1073582',
  '4127415',
  '2693919',
  '4662007',
  '3633921',
  '1138698',
  '5659195',
  '1076803',
  '5136677',
  '5558921',
  '3617738',
  '3749823',
  '5252484',
  '1905076',
  '4350281',
  '4594766',
  '3588555',
  '5544444',
  '1598549',
  '1793065',
  '1793251',
  '5027611',
  '2371698',
  '5507058',
  '3289040',
  '2513341',
  '5458354',
  '840344',
  '2524434',
  '984329',
  '2984298',
  '2593627',
  '5556787',
  '2374417',
  '4641909',
  '5698607',
  '4959430',
  '2027589',
  '675232',
  '3965859',
  '3663744',
  '2128082',
  '1680886',
  '5034101',
  '5583535',
  '5288238',
  '2521816',
  '5170600',
  '5299913',
  '2541163',
  '5390467',
  '5164014',
  '5357532',
  '1396467',
  '3611158',
  '4207190',
  '1912363',
  '2725768',
  '869384',
  '1280406',
  '3075815',
  '2393292',
  '1204381',
  '2142971',
  '5258849',
  '3611198',
  '1820785',
  '3379331',
  '1892026',
  '4796314',
  '4705936',
  '862347',
  '4807531',
  '3924364',
  '1203934',
  '5678725',
  '510806',
  '3349175',
  '3136838',
  '942538',
  '5345858',
  '3861622',
  '2072243',
  '2007811',
  '4390810',
  '2220274',
  '5271642',
  '5581069',
  '2378362',
  '3671075',
  '3596363',
  '3106492',
  '5317394',
  '5654342',
  '2720262',
  '2162193',
  '4723737',
  '3950462',
  '2894265',
  '984204',
  '5538534',
  '1444807',
  '184024',
  '1316971',
  '2798863',
  '939563',
  '3100543',
  '4316319',
  '2261157',
  '3252825',
  '1720181',
  '1429873',
  '4391347',
  '2275843',
  '4256057',
  '4243864',
  '3416421',
  '5567858',
  '4246310',
  '4420423',
  '5572209',
  '2477177',
  '5629867',
  '3559233',
  '5503465',
  '4792513',
  '4470662',
  '1448350',
  '5589013',
  '3848871',
  '5172405',
  '2981271',
  '5334008',
  '5291548',
  '4300298',
  '4927799',
  '5385629',
  '2519905',
  '2907978',
  '4922570',
  '480986',
  '4931931',
  '2342364',
  '2556549',
  '5259231',
  '4002035',
  '4167571',
  '2164107',
  '4250023',
  '4254454',
  '3934479',
  '4322360',
  '3926212',
  '4534028',
  '1785724',
  '5732512',
  '619675',
  '2779645',
  '1471271',
  '1498892',
  '5537382',
  '5183342',
  '3426033',
  '5242987',
  '1499319',
  '2271924',
  '3057379',
  '4070304',
  '2211842',
  '3718757',
  '1966693',
  '5021807',
  '1944702',
  '2955442',
  '4710790',
  '4263282',
  '2292829',
  '5357276',
  '1464135',
  '5223084',
  '1943566',
  '411126',
  '3408764',
  '4083807',
  '3523582',
  '2018472',
  '5261048',
  '1817665',
  '3323175',
  '5559282',
  '5236179',
  '2429465',
  '3333477',
  '869280',
  '5684551',
  '2791320',
  '2745199',
  '2293802',
  '2296996',
  '1979655',
  '2623578',
  '5291482',
  '3958610',
  '4394730',
  '1224300',
  '3668913',
  '4266773',
  '5133755',
  '4293757',
  '1940829',
  '4768392',
  '3675352',
  '5378577',
  '3053619',
  '5275690',
  '4852037',
  '5594343',
  '5366375',
  '3811709',
  '2467704',
  '2727276',
  '1915754',
  '2316586',
  '4712883',
  '5445277',
  '3133365',
  '1008091',
  '4772864',
  '4604677',
  '5489099',
  '4571761',
  '1133248',
  '2106402',
  '4059107',
  '4825087',
  '2083845',
  '129524',
  '5118529',
  '990663',
  '4382872',
  '2290767',
  '2772102',
  '4248368',
  '5268852',
  '2916384',
  '2724223',
  '974357',
  '2081576',
  '5546309',
  '1112695',
  '5146542',
  '3394853',
  '2758101',
  '2360986',
  '2390834',
  '2111979',
  '2099751',
  '5344268',
  '3582147',
  '4294317',
  '2340364',
  '4206252',
  '5486789',
  '4134460',
  '1063013',
  '5497297',
  '3953561',
  '4292595',
  '3448398',
  '5560269',
  '1622280',
  '2386815',
  '5586172',
  '4444668',
  '3877047',
  '3215446',
  '4303212',
  '1657537',
  '891291',
  '3784765',
  '3078729',
  '2254649',
  '1224039',
  '4065624',
  '2104030',
  '3694132',
  '4357678',
  '4295578',
  '2130420',
  '3422323',
  '4527409',
  '3750439',
  '3002347',
  '2043872',
  '3276829',
  '5334444',
  '1154539',
  '1833981',
  '4457071',
  '5263099',
  '4793351',
  '2363453',
  '5522579',
  '5564734',
  '1860562',
  '5624432',
  '1983709',
  '1418185',
  '4371675',
  '3091456',
  '3030379',
  '5131391',
  '2778491',
  '1063505',
  '4423882',
  '3553820',
  '1388588',
  '5377453',
  '5380120',
  '3961272',
  '4073571',
  '5579026',
  '5568836',
  '5284455',
  '5010286',
  '5582212',
  '1332096',
  '5299608',
  '3500901',
  '4489483',
  '5426834',
  '1141528',
  '5729350',
  '2024603',
  '3620779',
  '1708790',
  '3873363',
  '3909331',
  '5108803',
  '1241051',
  '4061770',
  '5543057',
  '1495574',
  '4928490',
  '487577',
  '5416515',
  '3553996',
  '3101670',
  '1288404',
  '5570733',
  '1936990',
  '2070635',
  '3264256',
  '4989159',
  '2180919',
  '4359746',
  '3944596',
  '4673901',
  '5509354',
  '5538162',
  '896660',
  '4667775',
  '1353794',
  '4719527',
  '5230238',
  '2222981',
  '2882297',
  '3843235',
  '4289593',
  '4994429',
  '3506169',
  '3480425',
  '899340',
  '2277280',
  '4473995',
  '1155230',
  '1555383',
  '2791482',
  '5491868',
  '4626891',
  '3746679',
  '5072625',
  '1689339',
  '4413683',
  '1382267',
  '5487112',
  '2342254',
  '5173662',
  '5584182',
  '2219913',
  '4147691',
  '2386776',
  '5552385',
  '2376033',
  '1220686',
  '5596012',
  '5254923',
  '5561301',
  '1909733',
  '4814064',
  '4184055',
  '5418012',
  '1969259',
  '2393791',
  '1275711',
  '3027613',
  '1282812',
  '1698896',
  '1801179',
  '2064271',
  '4779217',
  '4716023',
  '2567777',
  '5392949',
  '5413137',
  '1065533',
  '5295959',
  '2314688',
  '3834566',
  '186983',
  '2736648',
  '2337663',
  '1535787',
  '1038118',
  '2527231',
  '2419370',
  '4877818',
  '4607035',
  '4167557',
  '5436370',
  '3787316',
  '998900',
  '2773236',
  '3655160',
  '2307459',
  '1134240',
  '1370984',
  '3620598',
  '1603606',
  '4663656',
  '2886524',
  '2815382',
  '5559101',
  '1237033',
  '4765512',
  '2336104',
  '4712991',
  '2092807',
  '2303687',
  '1321579',
  '1355555',
  '5654006',
  '473236',
  '2104231',
  '3487481',
  '582299',
  '4453204',
  '5396698',
  '5562437',
  '5103535',
  '1051631',
  '5429610',
  '5540228',
  '2354004',
  '1293729',
  '3252131',
  '4998685',
  '1569460',
  '3938525',
  '5079034',
  '1642173',
  '5654946',
  '4730254',
  '5578132',
  '5088585',
  '1270509',
  '141061',
  '5165464',
  '1797768',
  '3248369',
  '1858918',
  '4715192',
  '579182',
  '5704775',
  '5497701',
  '2025076',
  '2751453',
  '2267969',
  '5319746',
  '2169498',
  '3743947',
  '2264591',
  '4302951',
  '5316324',
  '3673566',
  '4365237',
  '5260630',
  '3285199',
  '5424792',
  '4831134',
  '1629431',
  '3936927',
  '1713168',
  '5119281',
  '4511949',
  '3362916',
  '2669933',
  '2689866',
  '1841713',
  '5673054',
  '5029671',
  '2249978',
  '3533699',
  '4007264',
  '2367608',
  '5695210',
  '4974656',
  '4624419',
  '5377312',
  '4258658',
  '3174258',
  '2989533',
  '5496359',
  '359658',
  '1474258',
  '5456085',
  '965590',
  '2199962',
  '5361060',
  '3229070',
  '5477277',
  '2747644',
  '2958910',
  '2583794',
  '5512024',
  '5340689',
  '5333892',
  '4344660',
  '3795251',
  '5615312',
  '2313188',
  '2282273',
  '4790053',
  '4513932',
  '5679715',
  '2991547',
  '2958784',
  '1125296',
  '5571613',
  '2513994',
  '1383112',
  '5092109',
  '3907610',
  '2692434',
  '2809857',
  '432475',
  '3385835',
  '1750457',
  '5373299',
  '3666280',
  '1694653',
  '4895053',
  '2999956',
  '5422897',
  '4393847',
  '4428151',
  '5574942',
  '4036231',
  '4373338',
  '1034306',
  '3468927',
  '1144719',
  '3362438',
  '1114648',
  '4235081',
  '5494275',
  '3457480',
  '2221462',
  '4959013',
  '5536904',
  '5359689',
  '5137884',
  '4954751',
  '4723733',
  '3938408',
  '5552319',
  '2449352',
  '3185820',
  '2080713',
  '5538979',
  '1664580',
  '4892741',
  '4231702',
  '4028050',
  '5056733',
  '4911565',
  '3668853',
  '4220375',
  '4843565',
  '3758813',
  '831004',
  '5526310',
  '5547513',
  '3795071',
  '3636802',
  '4701388',
  '4444600',
  '4500575',
  '2448364',
  '5560842',
  '943966',
  '5585143',
  '3861315',
  '2792542',
  '5343216',
  '4131633',
  '4494575',
  '4213517',
  '5502056',
  '1345116',
  '5146036',
  '2410861',
  '4636086',
  '3841752',
  '5738124',
  '2233632',
  '4340473',
  '2009352',
  '1604134',
  '4959203',
  '5151974',
  '3000462',
  '3713482',
  '1109996',
  '5557952',
  '3099757',
  '1986202',
  '5581523',
  '5551762',
  '2081633',
  '2947182',
  '2243417',
  '1106969',
  '2558870',
  '5070372',
  '1450256',
  '3900198',
  '2359870',
  '4720020',
  '5600497',
  '4037677',
  '2259534',
  '3590721',
  '5267179',
  '1081263',
  '5470738',
  '4973780',
  '4777857',
  '4719512',
  '1563131',
  '3931325',
  '2502946',
  '5370255',
  '2320567',
  '5278056',
  '4553832',
  '5154546',
  '1361558',
  '2422803',
  '5381776',
  '4053014',
  '5589776',
  '5624274',
  '4642741',
  '5264245',
  '2767328',
  '5523851',
  '5058320',
  '5087664',
  '5565309',
  '4078934',
  '5124127',
  '5148738',
  '5298613',
  '4781912',
  '1012354',
  '5578450',
  '5412996',
  '2768982',
  '4254696',
  '2081801',
  '5657655',
  '1581572',
  '4872294',
  '4392530',
  '5361216',
  '3660245',
  '300747',
  '3766677',
  '4048448',
  '5447443',
  '2544268',
  '4853381',
  '1008189',
  '5045000',
  '1825427',
  '2233786',
  '2046253',
  '954197',
  '4437826',
  '5536088',
  '5552481',
  '4541129',
  '3323993',
  '5330073',
  '3469700',
  '3229800',
  '2831676',
  '4920223',
  '4011043',
  '5049595',
  '3804385',
  '5502054',
  '3946384',
  '1482077',
  '4079630',
  '5117496',
  '5478302',
  '5608917',
  '2196255',
  '4916830',
  '2887623',
  '5536227',
  '3200856',
  '4730257',
  '5434482',
  '3662736',
  '2613534',
  '4393122',
  '5592485',
  '5036790',
  '776255',
  '5280604',
  '3538887',
  '3154052',
  '5382256',
  '2603323',
  '1779928',
  '4891876',
  '3276714',
  '3966384',
  '2261789',
  '3272826',
  '4171975',
  '1737293',
  '3157480',
  '4074133',
  '5501581',
  '3079329',
  '4902375',
  '216074',
  '5405135',
  '4024758',
  '1871218',
  '4446490',
  '492372',
  '689548',
  '870810',
  '2282740',
  '2028518',
  '960762',
  '4187582',
  '1316246',
  '1157324',
  '1796172',
  '2239954',
  '1761841',
  '4756018',
  '2237986',
  '1158615',
  '1158970',
  '3793368',
  '2067832',
  '3024848',
  '2782997',
  '2563835',
  '1567092',
  '4726888',
  '2128186',
  '1095862',
  '3622102',
  '190026',
  '1709448',
  '1644921',
  '18676',
  '5213325',
  '1177291',
  '1927992',
  '1042657',
  '4176934',
  '2729542',
  '1258675',
  '1450520',
  '5201975',
  '4402339',
  '2342455',
  '2722957',
  '2210849',
  '2036257',
  '2214914',
  '30217',
  '1879689',
  '721837',
  '1676466',
  '1281248',
  '5515473',
  '4730959',
  '2300669',
  '1608621',
  '4665164',
  '4637751',
  '2224925',
  '1880510',
  '3027397',
  '1812330',
  '2235104',
  '1210725',
  '4582793',
  '3789981',
  '1915482',
  '3731781',
  '763740',
  '4186256',
  '4787593',
  '1254476',
  '3940282',
  '2086823',
  '1311723',
  '5608893',
  '971519',
  '3587437',
  '1169812',
  '5466956',
  '4116545',
  '123451',
  '1622920',
  '1447842',
  '2292221',
  '1928503',
  '1462860',
  '1217880',
  '155525',
  '4373186',
  '4395137',
  '5117425',
  '1226933',
  '1039577',
  '5029583',
  '5119880',
  '1388404',
  '4223266',
  '1213849',
  '1951357',
  '2461775',
  '2523385',
  '3583115',
  '4421001',
  '672956',
  '2036142',
  '2915751',
  '2453239',
  '4917613',
  '1208269',
  '4635235',
  '2949530',
  '2155413',
  '5593502',
  '1022845',
  '3440614',
  '3786845',
  '5533533',
  '1556303',
  '4737042',
  '3397164',
  '1150877',
  '3047814',
  '3632378',
  '2350195',
  '3339696',
  '1062836',
  '2780276',
  '681118',
  '2472222',
  '5693298',
  '4405320',
  '2540976',
  '4863998',
  '4202991',
  '5319364',
  '39434',
  '5117129',
  '1565319',
  '5906',
  '4905046',
  '1298308',
  '1079583',
  '1166471',
  '3623480',
  '2377889',
  '4827535',
  '2936119',
  '1444339',
  '136589',
  '2652668',
  '2724292',
  '1693777',
  '1264866',
  '2559548',
  '1550732',
  '2661739',
  '3493526',
  '1727695',
  '3930442',
  '3802422',
  '1851902',
  '1244177',
  '3611004',
  '681876',
  '2249728',
  '2084871',
  '1208864',
  '2877235',
  '2316977',
  '4058902',
  '3491201',
  '4000025',
  '2841955',
  '1149185',
  '953779',
  '2721791',
  '1851274',
  '1931711',
  '1797058',
  '2366061',
  '3172127',
  '4784098',
  '2305336',
  '5166703',
  '1945297',
  '1571429',
  '636167',
  '486709',
  '1164473',
  '891147',
  '5370736',
  '5089526',
  '4853671',
  '1627596',
  '2431514',
  '4108411',
  '1128748',
  '4825857',
  '1384585',
  '2545746',
  '5538367',
  '1312338',
  '4138380',
  '1488881',
  '5183325',
  '4114473',
  '1565266',
  '5532691',
  '993817',
  '123325',
  '434934',
  '1147202',
  '1165367',
  '1493618',
  '4663676',
  '836251',
  '1592245',
  '1271084',
  '2696454',
  '1387012',
  '4905476',
  '2684895',
  '1028824',
  '2198553',
  '2372329',
  '4725438',
  '2072431',
  '2704423',
  '819381',
  '1918773',
  '3898880',
  '500410',
  '2364726',
  '967711',
  '4081500',
  '4953314',
  '903454',
  '1308512',
  '5199848',
  '4027400',
  '2308986',
  '3572639',
  '1603536',
  '2067709',
  '1437814',
  '5273170',
  '2014439',
  '3700817',
  '3392001',
  '1017292',
  '3703137',
  '3651620',
  '4911396',
  '4088027',
  '1965502',
  '1964000',
  '3094746',
  '1991984',
  '2203799',
  '1985795',
  '4419408',
  '1987394',
  '326139',
  '2114230',
  '2275255',
  '1713784',
  '3624108',
  '4922556',
  '4258405',
  '5260707',
  '2801839',
  '2145383',
  '4887124',
  '4950110',
  '2263817',
  '2140960',
  '839419',
  '2305787',
  '4658968',
  '1162808',
  '161824',
  '3345944',
  '1807182',
  '1909021',
  '3064118',
  '1276966',
  '5232086',
  '5208824',
  '4716790',
  '597843',
  '2211955',
  '515953',
  '1137088',
  '2257140',
  '2630009',
  '1270940',
  '1709423',
  '1885012',
  '1968823',
  '4379401',
  '5167788',
  '5525072',
  '1599503',
  '1118031',
  '3425305',
  '9476',
  '1951104',
  '5231693',
  '1139541',
  '3548167',
  '974053',
  '4898874',
  '499999',
  '3920222',
  '3593203',
  '2945397',
  '2143003',
  '4300107',
  '3656179',
  '4033462',
  '2164872',
  '1365872',
  '3981901',
  '5493470',
  '5014323',
  '1403342',
  '1131278',
  '3646310',
  '4211724',
  '1074296',
  '1358431',
  '1487166',
  '2958467',
  '2846471',
  '4209813',
  '2128321',
  '4027696',
  '1701395',
  '1361374',
  '2968368',
  '5709254',
  '4728711',
  '1391095',
  '4005254',
  '1431502',
  '2455131',
  '2717153',
  '1179485',
  '2488602',
  '809108',
  '3458153',
  '4012997',
  '2935049',
  '283147',
  '3626145',
  '1383299',
  '2745770',
  '1846586',
  '1447262',
  '5008143',
  '3229607',
  '1129431',
  '796276',
  '2584389',
  '1804181',
  '1204806',
  '4037898',
  '1151484',
  '3670358',
  '1958369',
  '2108038',
  '972634',
  '2624595',
  '3430317',
  '423946',
  '5151311',
  '857729',
  '2482924',
  '378367',
  '3404025',
  '4407996',
  '3499634',
  '2625285',
  '4555675',
  '766656',
  '1346466',
  '4844644',
  '1885912',
  '4805765',
  '4542034',
  '4517022',
  '1733008',
  '2149925',
  '2406869',
  '3675953',
  '3535134',
  '3119632',
  '2080035',
  '4240976',
  '5125048',
  '3277659',
  '661608',
  '3255076',
  '1361736',
  '3008229',
  '3183401',
  '3767289',
  '4467762',
  '4388116',
  '437941',
  '2154423',
  '4371433',
  '4990949',
  '830960',
  '5469126',
  '2719361',
  '277973',
  '1482292',
  '2185497',
  '1620947',
  '3988183',
  '5450378',
  '1086458',
  '773026',
  '4840465',
  '1077428',
  '5453551',
  '3539245',
  '3547999',
  '845168',
  '1091934',
  '508155',
  '698807',
  '3818620',
  '3169011',
  '800355',
  '3498902',
  '1253764',
  '785338',
  '5017471',
  '3056343',
  '2599941',
  '2097581',
  '4672596',
  '766535',
  '5094297',
  '5584713',
  '2782492',
  '1405961',
  '5377032',
  '3121212',
  '420841',
  '5510558',
  '4742862',
  '2775627',
  '1126239',
  '1631984',
  '3876424',
  '4725243',
  '2960318',
  '3552012',
  '1453366',
  '1868426',
  '2670072',
  '4122154',
  '1109782',
  '1356460',
  '3422511',
  '3178794',
  '3947887',
  '3582708',
  '1993000',
  '1536713',
  '1079379',
  '3371049',
  '2207214',
  '3485340',
  '29642',
  '3847499',
  '4221712',
  '3807129',
  '1124570',
  '1736947',
  '3136913',
  '849131',
  '154284',
  '1366545',
  '3966725',
  '5407119',
  '4187144',
  '1965914',
  '674098',
  '3966144',
  '4296749',
  '2290563',
  '2426832',
  '2781619',
  '4758230',
  '1623653',
  '3208239',
  '2582001',
  '5062739',
  '2359483',
  '2977840',
  '4511146',
  '3221778',
  '608282',
  '1140761',
  '3640814',
  '1061821',
  '3288082',
  '3287169',
  '2130958',
  '1574853',
  '3157300',
  '834041',
  '1129128',
  '5721295',
  '4934597',
  '1628488',
  '2856605',
  '4603488',
  '1815823',
  '2892149',
  '2463288',
  '3887413',
  '4567081',
  '861692',
  '2959170',
  '1031451',
  '2091389'
];

export const PAYABLE_BILLING_CYCLE_STATUSES = [
  BillingCycleStatus.Payable,
  BillingCycleStatus.Overdue
];
