import { ECountry, ELocale } from 'App/i18n/i18n.types';
import { format, parseISO } from 'date-fns';
import { es, ptBR } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

import {
  Statement,
  StatementsByDate
} from 'domains/billing/pre-paid/wallet-statement/wallet-statement.types';

import { ArgentinaProvinces, MexicoProvinces } from '../types/commons';
import { GenericShipmentRequestDto } from '../types/genericShipmentRequestDto';

const shortTrackingCode = (tracking: string) => {
  return `${tracking.slice(0, 3)}...${tracking.slice(
    tracking.length - 6,
    tracking.length
  )}`;
};

const formatDocument = (value: string) => {
  const document = value.replace(/\D/g, '');

  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
};

const formatZipcode = (zipcode: string) => {
  const regex = /(^[0-9]{5})-?([0-9]{3}$)/;
  if (regex.test(zipcode)) {
    return zipcode;
  }

  return '';
};

const formatZipcodeFriendly = (zipcode: string) => {
  return zipcode.replace(/^(\d{5})(\d{3})$/, '$1-$2');
};

const groupStatementsByDate = (statements: Statement[]): StatementsByDate => {
  return statements.reduce<StatementsByDate>((acc, current) => {
    const currentDate = format(parseISO(current.createdAt), 'yyyy-MM-dd');

    if (!acc[currentDate]) acc[currentDate] = [];
    acc[currentDate].push(current);

    return acc;
  }, {});
};

const formatDate = (date: Date, format: string) => {
  return formatInTimeZone(date, 'UTC', format);
};

export const formatMonthFromIsoDate = (
  isoString: string,
  storeLanguage: string
) =>
  format(parseISO(isoString), 'LLLL', {
    locale: storeLanguage === 'pt-BR' ? ptBR : es
  });

const friendlyAddress = (
  address: GenericShipmentRequestDto.Address,
  country?: ECountry
) => {
  if (country === ECountry.ARGENTINA) {
    const province =
      address?.province && address?.province?.length > 2
        ? address.province
        : ArgentinaProvinces.find(
            (location) => location.code === address.province
          )?.name;

    if (address.neighborhood) {
      return `${address.address}, ${address.number} ${
        address.complement ? ` - ${address.complement}` : ''
      } - ${address.neighborhood} - ${address.city} - ${province}`;
    }

    return `${address.address}, ${address.number} ${
      address.complement ? ` - ${address.complement}` : ''
    } - ${address.city} - ${province}`;
  }

  if (country === ECountry.MEXICO) {
    const province =
      address.province.length > 3
        ? address.province
        : MexicoProvinces.find((location) => location.code === address.province)
            ?.name;

    if (address.neighborhood) {
      return `${address.address}, ${address.number} ${
        address.complement ? ` - ${address.complement}` : ''
      } - ${address.neighborhood} - ${address.city} - ${province}`;
    }

    return `${address.address}, ${address.number} ${
      address.complement ? ` - ${address.complement}` : ''
    } - ${address.city} - ${province}`;
  }

  return `${address.address}, ${address.number} ${
    address.complement ? ` - ${address.complement}` : ''
  } - ${address.neighborhood} - ${address.city} - ${address.province}`;
};

const currencyConfig = (locale: string) => {
  if (locale === ELocale.ARGENTINA || locale === ELocale.MEXICO) {
    return {
      symbol: '$',
      decimal: ',',
      separator: '.'
    };
  }

  return {
    symbol: 'R$',
    decimal: ',',
    separator: '.'
  };
};

const capitalizeFirstLetter = (value: string) => {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export class CpfCnpjUtils {
  static isCpfValid(cpf: string): boolean {
    const cpfLength = 11;
    const weights = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    return this.isValid(cpf, cpfLength, weights);
  }

  static isCnpjValid(cnpj: string): boolean {
    const cnpjLength = 14;
    const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6];

    return this.isValid(cnpj, cnpjLength, weights);
  }

  private static isValid(
    digits: string,
    correctDigitsLength: number,
    weights: number[]
  ): boolean {
    const cleanDigits = this.getOnlyNumbers(digits);
    if (
      cleanDigits.length !== correctDigitsLength ||
      this.isAllTheSameDigits(cleanDigits)
    ) {
      return false;
    }
    const digitsWithoutChecker = cleanDigits.substring(
      0,
      correctDigitsLength - 2
    );
    const digitsChecker = cleanDigits.substring(
      correctDigitsLength - 2,
      correctDigitsLength
    );
    const calculetedChecker = this.calcChecker(digitsWithoutChecker, weights);
    return digitsChecker === calculetedChecker;
  }

  private static getOnlyNumbers(digits: string): string {
    return digits.replace(/\D/g, '');
  }

  private static isAllTheSameDigits(digits: string): boolean {
    return !digits.split('').some((digit) => digit !== digits[0]);
  }

  private static calcChecker(digits: string, weights: number[]): string {
    const digitsLength = digits.length;
    const digitsLengthWithoutChecker = weights.length - 1;

    const sum = digits.split('').reduce((acc, digit, idx) => {
      return acc + +digit * weights[digitsLength - 1 - idx];
    }, 0);
    const sumDivisionRemainder = sum % 11;
    const checker = sumDivisionRemainder < 2 ? 0 : 11 - sumDivisionRemainder;

    if (digitsLength === digitsLengthWithoutChecker) {
      return this.calcChecker(`${digits}${checker}`, weights);
    }

    return `${digits[digitsLength - 1]}${checker}`;
  }
}

export {
  shortTrackingCode,
  formatDocument,
  groupStatementsByDate,
  formatZipcode,
  formatZipcodeFriendly,
  formatDate,
  friendlyAddress,
  currencyConfig,
  capitalizeFirstLetter
};
