export const AMP_PAGE_VIEW = 'nuvemenvio_view';
export const AMP_VIEW_WARNING = 'nuvemenvio_view_warning';
export const AMP_VIEW_DROPOFF = 'nuvemenvio_view_dropoff_banner';
export const AMP_BUTTON = 'nuvemenvio_click';

export enum AMP_PAGES {
  DROPOFF_POINTS = 'dropoff points',
  SINGLE_SHIPMENT_STEP1 = 'single_shipment_page1',
  SINGLE_SHIPMENT_STEP2 = 'single_shipment_page2',
  SINGLE_SHIPMENT_STEP3 = 'single_shipment_page3',
  SINGLE_SHIPMENT_STEP4 = 'single_shipment_page4',
  BANNER_LOGGI = 'Dashboard - Loggi invitation banner',
  CREATE_SUCURSAL = 'Create Sucursal',
  BILLING = 'billing',
  BILLING_HIGHLIGHT = 'Billing Highlight',
  BILLING_DETAILS = 'Billing Details',
  PREFERENCES = 'Preferences',
  CONFIGURATIONS = 'Configurations',
  DASHBOARD = 'dashboard',
  DASHBOARD_JADLOG = 'Dashboard - Jadlog invitation banner',
  DASHBOARD_EMPTY_STATE = 'dashboard - empty state',
  SHIPPING_DETAILS = 'Shipping Details',
  GENERATE_TRACKING_FROM_ORDERS = 'Generate Tracking from Orders',
  CONFIGURATIONS_CARRIER = 'Configurations - Carrier'
}

export enum AMP_ACTIONS {
  DROPOFF_GET_DIRECTIONS = 'get directions',
  DROPOFF_SELECT_DISTRIBUITION_CENTER = 'select distribution center',
  DROPOFF_SHOW_MORE_DROPOFF_POINTS = 'show more dropoff points',
  SINGLE_SHIPMENT_CONTINUE = 'continue',
  SINGLE_SHIPMENT_CANCEL = 'cancel',
  SINGLE_SHIPMENT_CREATE = 'create',
  DISMISS_JADLOG_INVITATION_BANNER = 'dismiss Jadlog invitation banner',
  DISMISS_LOGGI_INVITATION_BANNER = 'dismiss Loggi invitation banner',
  INTENTION_JADLOG_ACTIVATE_BANNER = 'intention to activate Jadlog',
  INTENTION_LOGGI_ACTIVATE_BANNER = 'intention to activate Loggi',
  LEARN_MORE_BANNER_LOGGI = 'learn more about Loggi',
  CREATE_SHIPMENT_SUCURSAL = 'Create Shipment',
  CREATE_SHIPMENT_SUCURSAL_CONTINUE = 'Continue',
  BALANCE_ADD = 'Top up - Add',
  BILLING_GO_TO_DETAILS = 'Go to Details',
  BILLING_DETAILS_CYCLE_SELECTOR = 'Billing Details - Cycle Selector',
  BILLING_DETAILS_SHIPPING_LABEL_REPORT = 'Billing Details - Shipping Label Report',
  BILLING_DETAILS_WALLET_STATEMENT = 'Billing Details - Wallet Statement',
  CONFIGURATION_OPEN_DELIVERY_OPTION = 'Open delivery option configuration from Nuvem Envio',
  DASHBOARD_OPEN_LANDING_PAGE_EMPTY_STATE = 'Open landing page from Nuvem Envio empty state',
  CONFIGURATION_OPEN_PREFERENCES = 'Open preferences from Nuvem Envio',
  CONFIGURATION_OPEN_SETTINGS = 'Open Settings',
  PREFERENCES_SAVE_SETTINGS = 'Save settings',
  PREFERENCES_DROPOFF_ACTIVATION = 'dropoff points - Dropoff activation',
  PREFERENCES_DROPOFF_ACTIVATION_ACTIVATE = 'activate - Dropoff activation',
  PREFERENCES_DROPOFF_ACTIVATION_ERROR = 'Error Activate - Pontos de Postagem',
  PREFERENCES_DROPOFF_ACTIVATION_TERMS = 'terms and conditions - Dropoff activation',
  DASHBOARD_MARK_AS_SENT = 'Mark as sent - Dashboard',
  DASHBOARD_MARK_AS_SENT_PANEL = 'Mark as sent - Panel',
  DASHBOARD_REPRINT_LABEL = 'Reprint label - Dashboard',
  DASHBOARD_BUY_LABEL = 'Buy label - Dashboard',
  DASHBOARD_CANCEL_LABEL = 'Cancel Label - Dashboard',
  DASHBOARD_GENERATE_REVERSE = 'Generate Reverse - Dashboard',
  DASHBOARD_DUPLICATE_SHIPPING = 'Duplicate Shipping - Dashboard',
  DASHBOARD_OPEN_JADLOG_DROPOFF = 'Open jadlog dropoff points',
  DASHBOARD_OPEN_LOGGI_DROPOFF = 'Open loggi dropoff points',
  DASHBOARD_OPEN_DROPOFF_POINTS = 'Open dropoff points',
  DASHBOARD_OPEN_FREIGHT_CALCULATOR = 'Open freight calculator',
  DASHBOARD_OPEN_SEARCH_MODAL = 'Open search modal',
  DASHBOARD_OPEN_TUTORIALS = 'Open Nuvem Envio tutorials',
  DASHBOARD_IMPORT_SALE_SHIPPING_TYPE_CHANGE = 'Import a sale from shipping type change',
  DASHBOARD_IMPORT_MULTIPLE_SALES = 'Import multiple sales',
  DASHBOARD_IMPORT_SALE = 'Import a sale',
  DASHBOARD_OTHER_SHIPMENTS = 'Other Shipments',
  DASHBOARD_CREATE_SINGLE_SHIPMENT = 'Create single shipment',
  DASHBOARD_CREATE_REVERSE_SHIPMENT = 'Create reverse shipment',
  DASHBOARD_CREATE_SUCURSAL_SHIPMENT = 'Create reverse shipment',
  DASHBOARD_ACTIVATE_DROPOFF_BANNER = 'activate - Dropoff Banner',
  DASHBOARD_DISMISS_DROPOFF_BANNER = 'dismiss - Dropoff Banner',
  DASHBOARD_LEARN_MORE_DROPOFF_BANNER = 'learn more - Dropoff Banner',
  DASHBOARD_ACTIVATE_JADLOG_BANNER = 'intention to activate Jadlog',
  DASHBOARD_DISMISS_JADLOG_BANNER = 'dismiss Jadlog invitation banner',
  DASHBOARD_LEARN_MORE_JADLOG_BANNER = 'learn more about Jadlog',
  DASHBOARD_EMPTY_STATE_ADD_CREDIT = 'Open add credit modal',
  DASHBOARD_EMPTY_STATE_OPEN_FREIGHT_CALCULATOR = 'Open freight calculator modal',
  EMPTY_STATE = 'empty-state',
  NO_PACKAGES = 'No Packages',
  NO_RESULTS = 'No results',
  SHIPPING_DETAILS_OPEN_SHIPPING_TYPE_CHANGE = 'Open shipping type change - Detail',
  SHIPPING_DETAILS_GO_TO_JADLOG_TRACKING = 'Shippment detail - go to jadlog tracking',
  SHIPPING_DETAILS_GO_TO_JADLOG_POINTS = 'Shippment detail - go to jadlog dropoff points',
  SHIPPING_DETAILS_GO_TO_DROPOFF_POINTS = 'Shippment detail - go to mandae dropoff points',
  SHIPPING_DETAILS_MARK_AS_SENT = 'Mark as sent - Detail',
  SHIPPING_DETAILS_DISPATCH_IN_PROGRESS = 'dispatch in progress',
  SHIPPING_DETAILS_DISPATCH_LIMIT = 'Limite de etiquetas atingido',
  SHIPPING_DETAILS_SAVE_CHANGE_FREIGHT = 'Save shipping type change - Detail',
  GENERATE_TRACKING_PRINT_BUTTON = 'button print',
  GENERATE_TRACKING_ADD_CREDIT_BUTTON = 'button add credit',
  GENERATE_TRACKING_NEED_RECHARGE = 'need recharge',
  GENERATE_TRACKING_CANCEL_BUTTON = 'button cancel',
  GENERATE_TRACKING_CREATE_BUTTON = 'Tracking creation/print - Panel',
  TUTORIALS_WATCH_VIDEO = 'Watch video',
  FILTERS_SEARCH = 'Search by modal',
  FREIGHT_CALCULATOR_CALCULATE = 'Calculate shipping',
  FREIGHT_CALCULATOR_CREATE_SHIPMENT = 'Create single shipment from calculator',
  GENERATE_ROMANEIO = 'Tracking creation/print - Panel - Romaneio',
  ONBOARDING_ADD_CREDIT_GO_TO_ACTION = 'Onboarding - highlight 1 - Go to Action',
  ONBOARDING_ADD_CREDIT_GO_TO_NEXT = 'Onboarding - highlight 1 - Next',
  ONBOARDING_ORDER_GO_TO_ACTION = 'Onboarding - highlight 2 - Go to Action',
  ONBOARDING_ORDER_GO_TO_NEXT = 'Onboarding - highlight 2 - Next',
  ONBOARDING_TUTORIALS_GO_TO_ACTION = 'Onboarding - highlight 3 - Go to Action',
  ONBOARDING_TUTORIALS_GO_TO_NEXT = 'Onboarding - highlight 3 - Next',
  LANDING_PAGE_SETUP_CLICK = 'envionube_landing_setup_click',
  LANDING_PAGE_VIEW_CLICK = 'envionube_landing_view_click',
  CONFIGURATIONS_CARRIER_BUTTON_GO_TO_POINTS = 'go to points',
  CONFIGURATIONS_CARRIER_BUTTON_EDIT = 'edit carrier',
  CONFIGURATIONS_CARRIER_BUTTON_ACTIVATE = 'activate carrier',
  CONFIGURATIONS_CARRIER_BUTTON_DISABLE_CONFIRM = 'confirm disable carrier',
  CONFIGURATIONS_CARRIER_BUTTON_DISABLE = 'disable carrier',
  CONFIGURATIONS_CARRIER_BUTTON_FREIGHT_CALCULATOR = 'freight calculator',
  EN_INVOICE_VIEW_DETAILS_CLICK = 'envionube_postpaid_invoice_view_details_click'
}
