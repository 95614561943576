import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import esAR from './landing-page.es-AR.json';
import esMX from './landing-page.es-MX.json';

const landingPageTranslations: DomainTranslations = {
  name: Domain.LANDING_PAGE,
  languages: {
    [ELocale.ARGENTINA]: esAR,
    [ELocale.MEXICO]: esMX,
    [ELocale.BRAZIL]: {}
  }
};

export default landingPageTranslations;
