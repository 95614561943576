/* eslint-disable sonarjs/no-identical-functions */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import {
  fetch,
  fetchCycles,
  fetchCycleID,
  fetchAdjustsByCycleId,
  fetchPrepaidLabelsReport,
  fetchPrepaidWalletReportDesktop,
  fetchPrepaidWalletReportMobile,
  fetchTypedCycle
} from '../billing';

const PERPAGE = 6;

export const useBillingCycles = () => {
  const fetchData = async ({ pageParam = 0 }) => {
    try {
      const results: any = await fetchCycles({
        offset: pageParam * PERPAGE,
        limit: PERPAGE
      });

      return {
        results,
        nextPage: pageParam + 1,
        totalPages: Math.ceil(results?.total / PERPAGE)
      };
    } catch (error) {}
  };

  return useInfiniteQuery(['billing-cycles'], fetchData, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage?.nextPage) {
        const nextPage = lastPage.nextPage;
        const totalPages = lastPage.totalPages;

        if (nextPage < totalPages) return nextPage;
      }

      return undefined;
    },
    keepPreviousData: true
  });
};

export const useBillingHighlight = () => {
  return useQuery(['billing-cycle-highlighted'], () =>
    fetchCycleID('highlighted')
  );
};

export const useBillingDetails = (cycleId: string) => {
  return useQuery(['billing-details', cycleId], () => fetchCycleID(cycleId), {
    keepPreviousData: false,
    enabled: false
  });
};

export const useTypedBillingDetails = (cycleId: string) => {
  return useQuery(
    ['billing-details-typed', cycleId],
    () => fetchTypedCycle(cycleId),
    {
      keepPreviousData: false,
      enabled: false
    }
  );
};

export const useBillingDetailsAdjusts = (id: string, params: any) => {
  return useQuery(
    ['billing-details-adjusts', id],
    () =>
      fetchAdjustsByCycleId(id, {
        limit: params.perpage,
        offset: (params.page - 1) * params.perpage,
        type: 'cycleAdjustment,billAdjustment'
      }),
    {
      keepPreviousData: false,
      enabled: false
    }
  );
};

export const useBillingDetailDesktop = (id: string, params: string) => {
  return useQuery(['billing-panel-desktop', id], () => fetch(id, params), {
    keepPreviousData: false,
    enabled: false
  });
};

export const useBillingDetailMobile = (id: string, perpage: number) => {
  const fetchData = async ({ pageParam = 0 }) => {
    const results: any = await fetch(
      id,
      `offset=${pageParam * perpage}&limit=${perpage}`
    );

    return {
      results,
      nextPage: pageParam + 1,
      totalPages: Math.ceil(results?.total / PERPAGE)
    };
  };

  return useInfiniteQuery(['billing-panel-mobile', id], fetchData, {
    getNextPageParam: (lastPage: any) => {
      const nextPage = lastPage.nextPage;
      const totalPages = lastPage.totalPages;

      if (nextPage < totalPages) return nextPage;

      return undefined;
    },
    keepPreviousData: true,
    enabled: false
  });
};

export const useBillingPaginate = (offset: number) => {
  return useQuery(
    ['billing-paginate'],
    () => fetchCycles({ offset: offset > 0 ? offset - 1 : 0, limit: 3 }),
    { enabled: false }
  );
};

export const useBillingPrepaidLabelsReport = (params: string) => {
  return useQuery(
    ['billing-prepaid-labels-report'],
    () => fetchPrepaidLabelsReport(params),
    { enabled: false, keepPreviousData: false }
  );
};

export const useBillingPrepaidWalletReportDesktop = (params: string) => {
  return useQuery(
    ['billing-prepaid-wallet-report'],
    () => fetchPrepaidWalletReportDesktop(params),
    { enabled: false, keepPreviousData: false }
  );
};

export const useBillingPrepaidWalletReportMobile = (params: string) => {
  return useQuery(
    ['billing-prepaid-wallet-report'],
    () => fetchPrepaidWalletReportMobile(params),
    { enabled: false, keepPreviousData: false }
  );
};
