import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from 'commons/utils/types/provider';

import {
  initialState,
  SingleShipmentState,
  Action,
  singleShipmentReducer
} from './reducer/single-shipment';

type SingleShipmentContextType = {
  state: SingleShipmentState;
  dispatch: React.Dispatch<Action>;
};

const SingleShipmentContext = createContext<
  SingleShipmentContextType | undefined
>(undefined);

const useSingleShipment = (): SingleShipmentContextType => {
  const [state, dispatch] = useReducer(singleShipmentReducer, initialState);

  return { state, dispatch };
};

const useSingleShipmentContext = (): SingleShipmentContextType => {
  const context = useContext(SingleShipmentContext);

  if (!context) {
    throw new Error(
      'useSingleShipmentContext must be used within a SingleShipmentProvider'
    );
  }

  return context;
};

const SingleShipmentProvider: React.FC<ProviderProps> = ({ children }) => {
  const single = useSingleShipment();

  return (
    <SingleShipmentContext.Provider value={single}>
      {children}
    </SingleShipmentContext.Provider>
  );
};

export { useSingleShipmentContext, SingleShipmentProvider };
