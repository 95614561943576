export const ROUTES = {
  DEFAULT: '/',
  ERROR: '/error',
  DASHBOARD: '/dashboard',
  SHIPPINGDETAILS: '/shipping-details/:id',
  BILLING: '/billing',
  BILLINGDETAILS: '/billing/details/:id',
  CONFIGURATIONS: '/configurations',
  CONFIGURATION_PREFERENCES: '/configurations/preferences',
  CONFIGURATION_CARRIER: '/configurations/carrier',
  SINGLE_SHIPMENT: '/single-shipment',
  SINGLE_SHIPMENT_QUOTATION: '/single-shipment/quotation',
  AGENCY_SHIPMENT: '/agency-shipment',
  AGENCY_SHIPMENT_QUOTATION: '/agency-shipment/quotation',
  REVERSE_SHIPMENT: '/reverse-shipment',
  REVERSE_SHIPMENT_QUOTATION: '/reverse-shipment/quotation',
  DROPOFF_POINTS: '/dropoff_points/:type',
  NEW_SINGLE_SHIPMENT: '/create-single-shipment',
  NEW_SINGLE_SHIPMENT_QUOTATION: '/create-single-shipment/quotation',
  NEW_SINGLE_SHIPMENT_CUSTOMER: '/create-single-shipment/customer',
  NEW_SINGLE_SHIPMENT_REVIEW: '/create-single-shipment/review',
  LANDING_PAGE: '/landing-page',
  HEALTH: '/health',
  AUTH: '/auth'
};
