import { ECountry } from 'App/i18n/i18n.types';

import {
  DeliveryOrderCreationType,
  DeliveryOrderDeliveryType,
  DeliveryOrderFreightType
} from '../types/commons';

export const CarrierJadLog = 'jadlog';
export const CarrierLoggi = 'loggi';
export const CarrierMandae = 'mandae';
export const CarrierCorreios = 'correios';

export const NECorreioSedex = 'ne-correios-sedex';
export const NECorreioPAC = 'ne-correios-pac';
export const NECorreioMini = 'ne-correios-mini';
export const NEMandaeEconomico = 'ne-mandae-economico';
export const NEMandaeExpress = 'ne-mandae-express';
export const NELoggiExpress = 'ne-loggi-express';
export const NELoggiEconomic = 'ne-loggi-economic';

export const NEAndreaniDomicilio = 'ne-andreani-domicilio';
export const NEAndreaniSucursal = 'ne-andreani-sucursal';

export const NECorreoArgClasicoDomicilio = 'ne-correo-arg-clasico-domicilio';
export const NECorreoArgClasicoSucursal = 'ne-correo-arg-clasico-sucursal';
export const NECorreoArgExpresoDomicilio = 'ne-correo-arg-expreso-domicilio';
export const NECorreoArgExpresoSucursal = 'ne-correo-arg-expreso-sucursal';

export const NEJadLog = 'ne-jadlog-package';
export const NEJadLogCom = 'ne-jadlog-com';

export const NEEnviaEstafetaGround = 'ne-envia-estafeta-ground';
export const NEEnviaEstafetaExpress = 'ne-envia-estafeta-express';
export const NEEnviaFedexGround = 'ne-envia-fedex-ground';
export const NEEnviaFedexExpress = 'ne-envia-fedex-express';
export const NEEnviaDhlGround = 'ne-envia-dhl-ground';
export const NEEnviaDhlExpress = 'ne-envia-dhl-express';
export const NEEnviaNoventa9MinutosLocalNextDay =
  'ne-envia-noventa9Minutos-local-next-day';
export const NEEnviaNoventa9MinutosNextDay =
  'ne-envia-noventa9Minutos-next-day';

const handleCarrierName = (carrierName: string): string => {
  switch (carrierName) {
    case CarrierJadLog:
      return 'Jadlog';
    case CarrierLoggi:
      return 'Loggi';
    case CarrierMandae:
      return 'Nuvem Envio';
    case CarrierCorreios:
      return 'Correios';
    default:
      return carrierName;
  }
};

const handleDeliveryOptionName = (code: string): string => {
  switch (code) {
    case NECorreioSedex:
      return 'Correios Sedex';
    case NECorreioPAC:
      return 'Correios PAC';
    case NECorreioMini:
      return 'Correios Mini';
    case NEMandaeEconomico:
      return 'Nuvem Envio Econômico';
    case NEMandaeExpress:
      return 'Nuvem Envio Rápido';
    case NELoggiExpress:
      return 'Loggi Express';
    case NELoggiEconomic:
      return 'Loggi Econômico';
    case NECorreoArgClasicoDomicilio:
      return 'Correo Argentino Clásico a domicilio';
    case NECorreoArgClasicoSucursal:
      return 'Correo Argentino Clásico a sucursal';
    case NECorreoArgExpresoDomicilio:
      return 'Correo Argentino Expreso a domicilio';
    case NECorreoArgExpresoSucursal:
      return 'Correo Argentino Expreso a sucursal';
    case NEJadLog:
      return 'Jadlog Econômico';
    case NEJadLogCom:
      return 'Jadlog Rápido';
    case NEAndreaniDomicilio:
      return 'Andreani a domicilio';
    case NEAndreaniSucursal:
      return 'Andreani a sucursal';
    case NEEnviaEstafetaGround:
      return 'Estafeta Terrestre';
    case NEEnviaEstafetaExpress:
      return 'Estafeta Express';
    case NEEnviaFedexGround:
      return 'FedEx Nacional Económico';
    case NEEnviaFedexExpress:
      return 'FedEx Nacional Día Siguiente';
    case NEEnviaDhlGround:
      return 'DHL Nacional Económico';
    case NEEnviaDhlExpress:
      return 'DHL Nacional Día Siguiente';
    case NEEnviaNoventa9MinutosLocalNextDay:
      return '99Minutos Local Día Siguiente';
    case NEEnviaNoventa9MinutosNextDay:
      return '99Minutos Nacional Estándar';
    default:
      return code;
  }
};

const handleShortDeliveryOptionName = (code: string): string => {
  switch (code) {
    case NECorreioSedex:
      return 'Sedex';
    case NECorreioPAC:
      return 'PAC';
    case NECorreioMini:
      return 'Mini';
    case NEMandaeEconomico:
      return 'Econômico';
    case NEMandaeExpress:
      return 'Rápido';
    case NELoggiExpress:
      return 'Rápido';
    case NELoggiEconomic:
      return 'Econômico';
    case NEJadLog:
      return 'Econômico';
    case NEJadLogCom:
      return 'Rápido';
    default:
      return code;
  }
};

const handleDeliveryType = (
  creationType: DeliveryOrderCreationType,
  deliveryType: DeliveryOrderDeliveryType,
  freightType?: DeliveryOrderFreightType
) => {
  if (creationType === 'sale') {
    if (freightType) {
      if (
        freightType === DeliveryOrderFreightType.NuvemEnvio ||
        freightType === DeliveryOrderFreightType.NativeCorreios ||
        freightType === DeliveryOrderFreightType.Fallback
      ) {
        return 'online-store';
      }

      return freightType;
    }

    return 'online-store';
  }

  if (creationType === 'detached') {
    return deliveryType;
  }
};

const enabledCancelattionCarrier = {
  [ECountry.BRAZIL]: [
    NECorreioSedex,
    NECorreioPAC,
    NECorreioMini,
    NEJadLog,
    NEJadLogCom
  ],
  [ECountry.ARGENTINA]: [
    NEAndreaniDomicilio,
    NEAndreaniSucursal,
    NECorreoArgClasicoDomicilio,
    NECorreoArgClasicoSucursal,
    NECorreoArgExpresoDomicilio,
    NECorreoArgExpresoSucursal
  ],
  [ECountry.MEXICO]: [
    NEEnviaEstafetaGround,
    NEEnviaEstafetaExpress,
    NEEnviaFedexGround,
    NEEnviaFedexExpress,
    NEEnviaDhlGround,
    NEEnviaDhlExpress,
    NEEnviaNoventa9MinutosLocalNextDay,
    NEEnviaNoventa9MinutosNextDay
  ]
};

export {
  handleDeliveryOptionName,
  handleDeliveryType,
  enabledCancelattionCarrier,
  handleShortDeliveryOptionName,
  handleCarrierName
};
