import { ECountry } from 'App/i18n/i18n.types';

import { PaginatedBillingCycle } from './paginated-billing-cycle';
import { StoreConfigsResponseDto } from './store-configs-response-dto';
import { StoreResponseDto } from './store-response-dto';

export enum BillingType {
  PREPAID = 'prepaid',
  POSTPAID = 'postpaid'
}

export type ActionStoreReducerType = {
  loading: boolean;
  store?: StoreResponseDto;
  configuration?: StoreConfigsResponseDto;
  country?: ECountry;
  currency?: string;
  language?: string;
  unpaidCycles?: PaginatedBillingCycle[];
};

type StandardLanguageCode =
  | 'es_AR'
  | 'es_MX'
  | 'es_CL'
  | 'es_CO'
  | 'pt_BR'
  | 'en_US';

type Name = {
  [key in StandardLanguageCode]: string;
};

interface NameWithWildcard extends Name {
  '*': string;
}

export interface DistributionCenter {
  externalId: string;
  name: NameWithWildcard;
}

export type OriginAddress = {
  address?: string;
  number?: string;
  city?: string;
  province?: string;
  country?: string;
  zipCode: string;
  complement?: string | null;
  neighborhood?: string;
  externalId: string;
  locations?: DistributionCenter[];
};
