import { Resource } from 'i18next';

import commonTranslations from 'commons/locale';

import agencyShipmentTranslations from 'domains/agency-shipment/locale';
import billingTranslations from 'domains/billing/locale/billing.locale';
import configurationCarrierTranslations from 'domains/configurations/carrier/locale';
import landingPageTranslations from 'domains/landing-page/locale/landing-page.locale';
import pointsTranslations from 'domains/points/locale';
import singleShipmentTranslations from 'domains/single-shipment/locale/single-shipment.locale';

import { ELocale, DomainTranslations } from './i18n.types';

export const languages: ELocale[] = [
  ELocale.ARGENTINA,
  ELocale.MEXICO,
  ELocale.BRAZIL
];

export const domains: DomainTranslations[] = [
  commonTranslations,
  agencyShipmentTranslations,
  billingTranslations,
  singleShipmentTranslations,
  pointsTranslations,
  landingPageTranslations,
  configurationCarrierTranslations
];

const resources = getResources(languages, domains);

export function getResources(
  languages: ELocale[],
  domains: DomainTranslations[]
): Resource {
  return languages.reduce((current, lang) => {
    const domain = domains.reduce(
      (currentDomain, domain) => ({
        ...currentDomain,
        [domain.name]: domain.languages[lang]
      }),
      {}
    );
    return { ...current, [lang]: domain };
  }, {});
}

export default resources;
