import { Suspense, FC, lazy } from 'react';

import { Routes, Route } from 'react-router-dom';

import { DashboardProvider } from 'commons/utils/hooks/useDashboardContext';
import { ExternalShipmentsProvider } from 'commons/utils/hooks/useExternalShipment';
import NexoSyncRoute from 'commons/utils/nexo/nexo-sync-route';

import Loading from 'domains/widgets/loading/loading';

import AppRedirect from './app-redirect';
import { ROUTES } from './constants';
import OrdersContextProvider from './ordersProvider';
import PreferencesContextProvider from './preferencesProvider';
import ShippingDetailsContextProvider from './shippingDetailsProvider';

const Auth = lazy(() => import('domains/auth'));
const Error = lazy(() => import('domains/error'));
const Dashboard = lazy(() => import('domains/dashboard'));
const Configurations = lazy(() => import('domains/configurations'));
const ConfigurationPreferences = lazy(
  () => import('domains/configurations/preferences')
);
const ConfigurationCarrier = lazy(
  () => import('domains/configurations/carrier')
);
const ShippingDetails = lazy(() => import('domains/shipping-details'));
const Billing = lazy(() => import('domains/billing'));
const BillingDetails = lazy(() => import('domains/billing/post-paid/details'));
const SingleShipment = lazy(
  () => import('domains/single-shipment/single-shipment')
);
const SingleShipmentQuotation = lazy(
  () => import('domains/single-shipment/quotation')
);
const ReverseShipment = lazy(
  () => import('domains/reverse-shipment/reverse-shipment')
);
const ReverseShipmentQuotation = lazy(
  () => import('domains/reverse-shipment/quotation')
);
const AgencyShipment = lazy(() => import('domains/agency-shipment'));
const AgencyShipmentQuotation = lazy(
  () => import('domains/agency-shipment/quotation')
);
const DropoffPoints = lazy(() => import('domains/points'));

const LandingPage = lazy(() => import('domains/landing-page'));

const CreateSingleShipment = lazy(
  () =>
    import(
      'domains/single-shipment/create-single-shipment/create-single-shipment'
    )
);

const CreateSingleShipmentQuotation = lazy(
  () => import('domains/single-shipment/create-single-shipment/quotation')
);
const CreateSingleShipmentCustomer = lazy(
  () => import('domains/single-shipment/create-single-shipment/customer')
);
const CreateSingleShipmentReview = lazy(
  () => import('domains/single-shipment/create-single-shipment/review')
);

const PublicRoutes: FC = () => (
  <Suspense fallback={<Loading />}>
    <NexoSyncRoute>
      <Routes>
        <Route
          path={ROUTES.ERROR}
          element={
            <Suspense fallback={<Loading />}>
              <Error />
            </Suspense>
          }
        />
        <Route element={<OrdersContextProvider />}>
          <Route
            path={ROUTES.DASHBOARD}
            element={
              <Suspense fallback={<Loading />}>
                <DashboardProvider>
                  <ExternalShipmentsProvider>
                    <Dashboard />
                  </ExternalShipmentsProvider>
                </DashboardProvider>
              </Suspense>
            }
          />
          <Route element={<ShippingDetailsContextProvider />}>
            <Route path={ROUTES.SHIPPINGDETAILS}>
              <Route
                path=":path/:cycleId"
                element={
                  <Suspense fallback={<Loading />}>
                    <ShippingDetails />
                  </Suspense>
                }
              />
              <Route
                path=""
                element={
                  <Suspense fallback={<Loading />}>
                    <ShippingDetails />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          <Route
            path={ROUTES.SINGLE_SHIPMENT}
            element={
              <Suspense fallback={<Loading />}>
                <SingleShipment />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.SINGLE_SHIPMENT_QUOTATION}
            element={
              <Suspense fallback={<Loading />}>
                <SingleShipmentQuotation />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.AGENCY_SHIPMENT}
            element={
              <Suspense fallback={<Loading />}>
                <AgencyShipment />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.AGENCY_SHIPMENT_QUOTATION}
            element={
              <Suspense fallback={<Loading />}>
                <AgencyShipmentQuotation />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.REVERSE_SHIPMENT}
            element={
              <Suspense fallback={<Loading />}>
                <ReverseShipment />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.REVERSE_SHIPMENT_QUOTATION}
            element={
              <Suspense fallback={<Loading />}>
                <ReverseShipmentQuotation />
              </Suspense>
            }
          />
          <Route path={ROUTES.NEW_SINGLE_SHIPMENT}>
            <Route
              path="edit"
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipment />
                </Suspense>
              }
            />
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipment />
                </Suspense>
              }
            />
          </Route>

          <Route path="/landing-page" element={<LandingPage />} />

          <Route path={ROUTES.NEW_SINGLE_SHIPMENT_QUOTATION}>
            <Route
              path="edit"
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipmentQuotation />
                </Suspense>
              }
            />
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipmentQuotation />
                </Suspense>
              }
            />
          </Route>

          <Route path={ROUTES.NEW_SINGLE_SHIPMENT_CUSTOMER}>
            <Route
              path="edit"
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipmentCustomer />
                </Suspense>
              }
            />
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <CreateSingleShipmentCustomer />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={ROUTES.NEW_SINGLE_SHIPMENT_REVIEW}
            element={
              <Suspense fallback={<Loading />}>
                <CreateSingleShipmentReview />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={ROUTES.CONFIGURATIONS}
          element={
            <Suspense fallback={<Loading />}>
              <Configurations />
            </Suspense>
          }
        />

        <Route element={<PreferencesContextProvider />}>
          <Route
            path={ROUTES.CONFIGURATION_PREFERENCES}
            element={
              <Suspense fallback={<Loading />}>
                <ConfigurationPreferences />
              </Suspense>
            }
          />
        </Route>

        <Route element={<OrdersContextProvider />}>
          <Route
            path={ROUTES.CONFIGURATION_CARRIER}
            element={
              <Suspense fallback={<Loading />}>
                <ConfigurationCarrier />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={ROUTES.BILLING}
          element={
            <Suspense fallback={<Loading />}>
              <Billing />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.BILLINGDETAILS}
          element={
            <Suspense fallback={<Loading />}>
              <BillingDetails />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.DROPOFF_POINTS}
          element={
            <Suspense fallback={<Loading />}>
              <DropoffPoints />
            </Suspense>
          }
        />

        <Route
          path={ROUTES.LANDING_PAGE}
          element={
            <Suspense fallback={<Loading />}>
              <LandingPage />
            </Suspense>
          }
        />

        {process.env.REACT_APP_CURRENT_ENVIRONMENT === 'staging' && (
          <Route
            path={ROUTES.AUTH}
            element={
              <Suspense fallback={<Loading />}>
                <Auth />
              </Suspense>
            }
          ></Route>
        )}

        <Route path={ROUTES.HEALTH} element={<div>Ok</div>}></Route>

        <Route path="/" element={<AppRedirect />}></Route>
      </Routes>
    </NexoSyncRoute>
  </Suspense>
);

export default PublicRoutes;
