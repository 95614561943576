import { ECountry, ELocale } from 'App/i18n/i18n.types';

import {
  ActionStoreReducerType,
  BillingType,
  OriginAddress
} from 'commons/utils/types/common';
import { NuvemEnvioCategory } from 'commons/utils/types/commons';
import { PaginatedBillingCycle } from 'commons/utils/types/paginated-billing-cycle';
import { StoreConfigsResponseDto } from 'commons/utils/types/store-configs-response-dto';

export type StoresState = {
  loading: boolean;
  country: ECountry;
  currency: string;
  language: string;
  configuration: StoreConfigsResponseDto;
  billingType: BillingType;
  externalCarrierId: string;
  externalStoreId: string;
  deliveryOptionCode: string[];
  address: OriginAddress;
  unpaidCycles: PaginatedBillingCycle[];
};

const initialState: StoresState = {
  loading: true,
  address: {
    externalId: '',
    address: '',
    number: '',
    city: '',
    province: '',
    country: '',
    zipCode: '',
    complement: '',
    neighborhood: '',
    locations: []
  },
  billingType: BillingType.PREPAID,
  deliveryOptionCode: [],
  externalCarrierId: '',
  externalStoreId: '',
  configuration: {
    hasFinancialIssues: false,
    hasAdminBlocked: false,
    hasLocations: false,
    hasCreatedDispatch: true,
    showGoPlusBanner: false,
    showJadlogActivationBanner: false,
    mandaeConfig: {
      category: NuvemEnvioCategory.COLLECT,
      d2dConnected: false,
      declaredQuotationValue: false,
      trackingPrefix: ''
    },
    displaySwitches: {
      showLoggiActivationBanner: false
    }
  },
  country: ECountry.BRAZIL,
  currency: 'BRL',
  language: ELocale.BRAZIL,
  unpaidCycles: []
};

export type Action = {
  type: 'SET_DEFINITIONS';
  payload: ActionStoreReducerType;
};

const storeReducer = (state: StoresState, action: Action): StoresState => {
  switch (action.type) {
    case 'SET_DEFINITIONS':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export { initialState, storeReducer };
