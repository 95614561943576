import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import ptBR from './points.pt-BR.json';

const pointsTranslations: DomainTranslations = {
  name: Domain.POINTS,
  languages: {
    [ELocale.ARGENTINA]: {},
    [ELocale.MEXICO]: {},
    [ELocale.BRAZIL]: ptBR
  }
};

export default pointsTranslations;
