enum Domain {
  AGENCY = 'agency-shipment',
  BILLING = 'billing',
  SINGLE_SHIPMENT = 'single-shipment',
  POINTS = 'points',
  LANDING_PAGE = 'landing-page',
  CONFIGURATION_CARRIER = 'configurations/carrier'
}

export default Domain;
