import { ELocale, DomainTranslations } from 'App/i18n/i18n.types';

import Domain from 'commons/utils/domains';

import ptBR from './carrier.pt-BR.json';

const configurationCarrierTranslations: DomainTranslations = {
  name: Domain.CONFIGURATION_CARRIER,
  languages: {
    [ELocale.ARGENTINA]: {},
    [ELocale.MEXICO]: {},
    [ELocale.BRAZIL]: ptBR
  }
};

export default configurationCarrierTranslations;
