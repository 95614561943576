import { Outlet } from 'react-router-dom';

import { ShippingDetailsErrorProvider } from 'commons/utils/hooks/useShippingDetailsErrorContext';

const SingleShippingContextProvider = () => {
  return (
    <ShippingDetailsErrorProvider>
      <Outlet />
    </ShippingDetailsErrorProvider>
  );
};

export default SingleShippingContextProvider;
