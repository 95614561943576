import { Outlet } from 'react-router-dom';

import { FiltersProvider } from 'commons/utils/hooks/useFilters';
import { GenericShipmentProvider } from 'commons/utils/hooks/useGenericShipment';

import { SingleShipmentProvider } from 'domains/single-shipment/context/useSingleShipment';

const OrdersContextProvider = () => {
  return (
    <GenericShipmentProvider>
      <SingleShipmentProvider>
        <FiltersProvider>
          <Outlet />
        </FiltersProvider>
      </SingleShipmentProvider>
    </GenericShipmentProvider>
  );
};

export default OrdersContextProvider;
